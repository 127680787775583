import { Tab } from "@headlessui/react";
import StayCard from "components/StayCard/StayCard";
import { FC, Fragment, useState } from "react";
import { Helmet , HelmetProvider} from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import AccountSideBar from "./AccountSideBar";
import { CONSTANTS } from "data/constants";
import { useTranslation } from "react-i18next";
import { VenueTypes } from "components/HeroSearchForm/type";
import { useGetProviderVenuesQuery } from "state/api/apiSlice";
import DefaultLoading from "shared/Loading/DefaultLoading";
import ProviderStayCard from "./ProviderStayCard";

export interface AccountPageProps {
  className?: string;
}

interface ListingSectionProps {
  providerID: number;
}

const ListingSection : FC<ListingSectionProps>= ({ providerID }) => {
  const { data, error, isLoading, refetch } = useGetProviderVenuesQuery({ provider_id: providerID });
  const {t} = useTranslation();

  if (isLoading) return <DefaultLoading />;
  if (error) return <p>Error loading data</p>;

  const weddingHalls = data?.venues.filter((venue) => venue.type === 'wedding_hall') || [];
  const conferenceHalls = data?.venues.filter((venue) => venue.type === 'conference_hall') || [];
  const restaurants = data?.venues.filter((venue) => venue.type === 'restaurant') || [];

  const categories : VenueTypes[] = ['wedding_hall', 'conference_hall', 'restaurant']; 

  return (
    <div className="listingSection__wrap">
      <div>
        <h2 className="text-2xl font-semibold">{t('navigation.venues')}</h2>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      <div>
        <Tab.Group>
          <Tab.List className="flex space-x-1 rtl:space-x-reverse overflow-x-auto">
            {categories.map((item) => (
              <Tab key={item} as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${selected
                      ? "bg-secondary-900 text-secondary-50 "
                      : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    {t(`venueTypes.${item}`)}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">

                {weddingHalls.map((stay) => (
                  <ProviderStayCard key={stay.id} data={stay} onDelete={()=>refetch()} />
                ))}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                {conferenceHalls.map((stay) => (
                  <ProviderStayCard key={stay.id} data={stay} onDelete={()=>refetch()} />
                ))}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                {restaurants.map((stay) => (
                  <ProviderStayCard key={stay.id} data={stay} onDelete={()=>refetch()} />
                ))}
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};



const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  let [categories] = useState<VenueTypes[]>(['wedding_hall', "conference_hall", "restaurant"]);
  const {t} = useTranslation();

  const user = useSelector((state: RootState) => state.user.details);


  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <HelmetProvider>
        <Helmet>
          <title>{t('navigation.account')}</title>
        </Helmet>
      </HelmetProvider>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">
            {user && <AccountSideBar user={user}/>}
          </div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:ps-10 flex-shrink-0">
          {user?.type === CONSTANTS.userType.provider && <ListingSection providerID={user.id}/>}
        </div>
      </main>
    </div>
  );
};

export default AccountPage;
