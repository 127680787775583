import React, { useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import DefaultLoading from "shared/Loading/DefaultLoading";

export interface CommonLayoutProps {
  index: string;
  nextHref: string;
  backtHref: string;
  nextBtnText?: string;
  runBeforeNext?: () => Promise<void>;
  isNextButtonDisabled?: boolean; // Add this prop
  children: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index = "01",
  children,
  nextHref,
  nextBtnText,
  backtHref,
  runBeforeNext,
  isNextButtonDisabled = false, // Default value
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 10
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5 rtl:space-x-reverse">
          <ButtonSecondary onClick={() => navigate(backtHref)}>{t('venueDetails.goBack')}</ButtonSecondary>
          <ButtonPrimary
            disabled={isNextButtonDisabled} // Disable the button based on the prop
            onClick={async () => {
              if (runBeforeNext) {
                setIsLoading(true);
                try {
                  await runBeforeNext();
                  setIsLoading(false);
                  navigate(nextHref);
                } catch (error) {
                  setIsLoading(false);
                }
              } else {
                navigate(nextHref);
              }
            }}
          >
            {isLoading ? <DefaultLoading /> : nextBtnText || t('auth.continue')}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
