import DatePicker from "react-datepicker";
import React, { FC, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSearchDate } from "state/venueSearchSlice";
import { convertDateToDateOnlyString } from "utils/convertSelectedDateToString";
import { RootState } from "state/store";

export interface StayDatesRangeInputProps {
  className?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const date = useSelector((state: RootState) => state.venueSearch.date);

  const onChangeDate = (date: Date | null) => {
    if (date !== null) {
      dispatch(setSearchDate(convertDateToDateOnlyString(date)))
    } else {
      dispatch(setSearchDate(undefined))
    }
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {t('filterVenues.whenIsYourEvent')}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >

        <DatePicker
          selected={new Date(date ?? '')}
          onChange={onChangeDate}
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => (
            <DatePickerCustomHeaderTwoMonth {...p} />
          )}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
