import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';

interface LanguageState {
    currentLanguage: string;
    direction: 'rtl' | 'ltr';
}

// Retrieve the initial language from local storage or default to 'ar'
const initialLanguage = localStorage.getItem('language') || 'ar';

const initialState: LanguageState = {
    currentLanguage: initialLanguage,
    direction: initialLanguage === 'ar' ? 'rtl' : 'ltr'
};

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguageAndDirection: (state, action: PayloadAction<string>) => {
            const newLanguage = action.payload;
            i18n.changeLanguage(newLanguage);
            state.currentLanguage = newLanguage;
            localStorage.setItem('language', newLanguage); // Save language to local storage
            if (newLanguage === 'ar') {
                state.direction = 'rtl';
            } else {
                state.direction = 'ltr';
            }

        },
    },
});

export const { setLanguageAndDirection } = languageSlice.actions;

export default languageSlice.reducer;
