import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export interface GuestsInputProps {
  defaultValue?: GuestsObject;
  onChange?: (data: GuestsObject) => void;
  className?: string;
  capacity: number;
  maxCapacity: number;
  setCapacity: (value: number) => void;
}

const GuestsInput: FC<GuestsInputProps> = ({
  className = "",
  capacity,
  maxCapacity,
  setCapacity,
}) => {
  const { t } = useTranslation();

  const handleChangeData = (value: number) => {
    setCapacity(value);
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <NcInputNumber
        className="w-full"
        defaultValue={capacity}
        onChange={handleChangeData}
        min={1}
        max={maxCapacity}
        label={t('filterVenues.guests')}
        desc={t('filterVenues.numberOfPeopleComing')}
      />
    </div>
  );
};

export default GuestsInput;
