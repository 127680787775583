import React, { FC } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import Input from "shared/Input/Input";

interface CustomInputLabelProps {
    label: string;
    type?: string;
    register: UseFormRegisterReturn;
    placeholder?: string;
}

const CustomInput: FC<CustomInputLabelProps> = ({ label, type, register, placeholder }) => {
    return (
        <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
                {label}
            </span>
            <Input {...register} placeholder={placeholder} type={type} className="mt-1" />
        </label>
    );
};

export default CustomInput;