import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVenue } from "state/selectedVenueSlice";
import { useTranslation } from "react-i18next";
import StartRating from "components/StartRating/StartRating";
import { Venue } from "types/venue";
import { RootState } from "state/store";

export interface SimpleVenueCardProps {
    className?: string;
    data: Venue;
    size?: "default" | "small";
}

const SimpleVenueCard: FC<SimpleVenueCardProps> = ({
    size = "default",
    className = "",
    data,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentLanguage } = useSelector((state: RootState) => state.language);


    const handleCardClick = () => {
        dispatch(setSelectedVenue(data));
    };

    return (
        <div
            className={`nc-SimpleVenueCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
            data-nc-id="SimpleVenueCard"
            onClick={handleCardClick}
        >
            <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
                <div className="space-y-2">
                    <span className="text-sm text-neutral-500 dark:text-neutral-400">
                        {t(`venueTypes.${data.type}`)} · {data.capacity} {t('filterVenues.guests')}
                    </span>
                    <div className="flex items-center space-x-2">
                        <h2 className={`font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}>
                            <span className="line-clamp-1">{currentLanguage === 'en' ? data.name : data.name_ar}</span>

                        </h2>
                    </div>
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        <span>{currentLanguage === 'en' ? data.city : data.city_ar}</span>
                    </div>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                <div className="flex justify-between items-center">
                    <span className="text-base font-semibold">
                        {data.budget} {' '}
                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                            {t('currency.sar')}/{data.type === 'wedding_hall' ? t('venueDetails.day') : t('venueDetails.hour')}
                        </span>
                    </span>
                    {!!data.star_rating && (
                        <StartRating point={data.star_rating} />
                    )}
                </div>
            </div>
            <Link to={`/orders/${data.id}`} className="absolute inset-0 z-10"></Link>
        </div>
    );
};

export default SimpleVenueCard;
