import React, { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { Link, useNavigate } from "react-router-dom";
import { Venue } from "types/venue";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "state/store";
import { useDeleteVenueMutation } from "state/api/apiSlice";

export interface ProviderStayCardProps {
  className?: string;
  data: Venue;
  onDelete: () => void;
  size?: "default" | "small";
}

const ProviderStayCard: FC<ProviderStayCardProps> = ({
  size = "default",
  className = "",
  data,
  onDelete
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state: RootState) => state.language);
  const [deleteVenue] = useDeleteVenueMutation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteVenue({ venueID: data.id }).unwrap();
      onDelete()
      setIsDeleteModalOpen(false)
  } catch (error) {
      console.error("Failed to delete the venue:", error);
  }
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${data.id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={data.images}
          href={`/venue/${data.id}`}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <Link to={`/venue/${data.id}`}>
            <div className="space-y-2">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {t(`venueTypes.${data.type}`)} · {data.capacity} {t('filterVenues.guests')}
            </span>
            <div className="flex items-center space-x-2">
                <h2 className={`font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}>
                <span className="line-clamp-1">
                    {currentLanguage === 'en' ? data.name : data.name_ar}
                </span>
                </h2>
            </div>
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                {size === "default" && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                </svg>
                )}
                <span className="">{currentLanguage === 'en' ? data.city : data.city_ar}</span>
            </div>
            </div>
            <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
            <div className="flex justify-between items-center">
            <span className="text-base font-semibold">
                {data.budget}
                {` `}
                {size === "default" && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                    {t('currency.sar')}/{data.type === 'wedding_hall' ? t('venueDetails.day') : t('venueDetails.hour')}
                </span>
                )}
            </span>
            {!!data.star_rating && <StartRating point={data.star_rating} />}
            </div>
        </Link>
        <button className="mt-4 px-4 py-2 bg-red-600 text-white rounded-md" onClick={() => setIsDeleteModalOpen(true)}>
          Delete
        </button>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-xl font-semibold mb-4">Are you sure you want to delete this venue?</h2>
            <div className="flex justify-end space-x-4 rtl:space-x-reverse">
              <button className="px-4 py-2 bg-gray-200 text-black rounded-md" onClick={() => setIsDeleteModalOpen(false)}>
                Cancel
              </button>
              <button className="px-4 py-2 bg-red-600 text-white rounded-md" onClick={handleDelete}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProviderStayCard;
