import React, { FC, useState } from "react";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useGetOrderByIdQuery, useGetVenueByIdQuery, useDeleteOrderMutation, useAcceptOrDeclineOrderMutation } from "state/api/apiSlice";
import StatusSection from "./StatusSection";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { CONSTANTS } from "data/constants";

export interface OrderStatusPageProps {
    className?: string;
}

const OrderStatusPage: FC<OrderStatusPageProps> = ({ className = "" }) => {
    const { id } = useParams<{ id: string }>();
    const { data: orderData } = useGetOrderByIdQuery({ order_id: Number(id) });
    const { data: venueData } = useGetVenueByIdQuery({ venue_id: Number(orderData?.order.venue_id) });
    const userType = useSelector((state: RootState) => state.user.details?.type);

    const [deleteOrder] = useDeleteOrderMutation();
    const [acceptOrDeclineOrder] = useAcceptOrDeclineOrderMutation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
    const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);

    const handleDelete = async () => {
        try {
            await deleteOrder({ orderId: Number(id) }).unwrap();
            navigate("/orders");
        } catch (error) {
            console.error("Failed to delete the order:", error);
        }
    };

    const handleAccept = async () => {
        try {
            await acceptOrDeclineOrder({ orderId: Number(id), body:{effect:'accept'}}).unwrap();
            navigate("/orders");
        } catch (error) {
            console.error("Failed to accept the order:", error);
        }
    };

    const handleDecline = async () => {
        try {
            await acceptOrDeclineOrder({ orderId: Number(id), body:{effect:'decline'}}).unwrap();
            navigate("/orders");
        } catch (error) {
            console.error("Failed to decline the order:", error);
        }
    };

    const renderSidebar = () => {
        return (
            <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
                <div className="flex flex-col sm:flex-row sm:items-center">
                    <div className="flex-shrink-0 w-full sm:w-40">
                        <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                            {venueData?.venue?.images[0] && (
                                <img
                                    alt=""
                                    className="absolute inset-0 object-cover"
                                    sizes="200px"
                                    src={venueData?.venue?.images[0].image_url}
                                />
                            )}
                        </div>
                    </div>
                    <div className="py-5 sm:px-5 space-y-3">
                        <div>
                            <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                                {venueData?.venue?.name}
                            </span>
                            <span className="text-base font-medium mt-1 block">{venueData?.venue?.city}</span>
                        </div>
                        <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                            {venueData?.venue?.provider.company_name}
                        </span>
                        <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
                        {!!venueData?.venue.star_rating && <StartRating point={venueData?.venue?.star_rating} />}
                    </div>
                </div>
                <div className="flex flex-col space-y-4">
                    <h3 className="text-2xl font-semibold">Price detail</h3>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <span>Total</span>
                        <span>{t("currency.sar")} {orderData?.order?.price}</span>
                    </div>
                </div>
            </div>
        );
    };

    const renderMain = () => {
        return (
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <div>
                    <div>
                        <h3 className="text-2xl font-semibold">Reservation Details</h3>
                        <NcModal
                            renderTrigger={(openModal) => (
                                <span onClick={() => openModal()} className="block lg:hidden underline mt-1 cursor-pointer">
                                    View booking details
                                </span>
                            )}
                            renderContent={renderSidebar}
                            modalTitle="Booking details"
                        />
                    </div>
                    <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
                        <div className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800">
                            <div className="flex flex-col">
                                <span className="text-sm text-neutral-400">Date</span>
                                <span className="mt-1.5 text-md font-semibold">
                                    From: {orderData && new Date(orderData?.order?.reservation_period.lower).toLocaleString()}
                                </span>
                                <span className="mt-1.5 text-md font-semibold">
                                    To: {orderData && new Date(orderData?.order?.reservation_period.upper).toLocaleString()}
                                </span>
                            </div>
                        </div>
                    
                
                        <div className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800">
                            <div className="flex flex-col">
                                <span className="text-sm text-neutral-400">Guests</span>
                                <span className="mt-1.5 text-lg font-semibold">
                                    <span className="line-clamp-1">{`${orderData?.order?.capacity} Guests`}</span>
                                </span>
                            </div>
                        </div>
                            
                    </div>
                    {userType === CONSTANTS.userType.customer && orderData && <StatusSection status={orderData?.order.status} orderId={orderData.order.id} />}
                    {userType === CONSTANTS.userType.customer && (orderData?.order.status === 'Pending Approval' || 'Approved - Pending Payment') && 
                        <div className="mt-6">
                            <button className="px-4 py-2 bg-red-600 text-white rounded-md" onClick={() => setIsDeleteModalOpen(true)}>
                                Delete Reservation
                            </button>
                        </div>
                    }
                    {userType === CONSTANTS.userType.customer  && (orderData?.order.status === "Confirmed")&& 
                        <div className="mt-6">
                            <p>
                                {venueData?.venue.provider.company_name} number is {venueData?.venue.provider.phone_number} call them for details
                                </p>
                        </div>
                    }
                    {userType === CONSTANTS.userType.provider && (orderData?.order.status === 'Pending Approval')&& 
                        <div className="mt-6 flex space-x-4">
                            <button className="px-4 py-2 bg-green-600 text-white rounded-md" onClick={() => setIsAcceptModalOpen(true)}>
                                Accept Order
                            </button>
                            <button className="px-4 py-2 bg-red-600 text-white rounded-md" onClick={() => setIsDeclineModalOpen(true)}>
                                Decline Order
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    };

    return (
        <div className={`nc-OrderStatusPage ${className}`}>
            <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
                <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
            </main>

            {isDeleteModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-md">
                        <h2 className="text-xl font-semibold mb-4">Are you sure you want to delete this reservation?</h2>
                        <div className="flex justify-end space-x-4">
                            <button className="px-4 py-2 bg-gray-200 text-black rounded-md" onClick={() => setIsDeleteModalOpen(false)}>
                                Cancel
                            </button>
                            <button className="px-4 py-2 bg-red-600 text-white rounded-md" onClick={handleDelete}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isAcceptModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-md">
                        <h2 className="text-xl font-semibold mb-4">Are you sure you want to accept this order?</h2>
                        <div className="flex justify-end space-x-4">
                            <button className="px-4 py-2 bg-gray-200 text-black rounded-md" onClick={() => setIsAcceptModalOpen(false)}>
                                Cancel
                            </button>
                            <button className="px-4 py-2 bg-green-600 text-white rounded-md" onClick={handleAccept}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isDeclineModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-md">
                        <h2 className="text-xl font-semibold mb-4">Are you sure you want to decline this order?</h2>
                        <div className="flex justify-end space-x-4">
                            <button className="px-4 py-2 bg-gray-200 text-black rounded-md" onClick={() => setIsDeclineModalOpen(false)}>
                                Cancel
                            </button>
                            <button className="px-4 py-2 bg-red-600 text-white rounded-md" onClick={handleDecline}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderStatusPage;
