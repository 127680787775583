import { OrderStatus } from "types/order";

export const orderStatusTranslations: { [key in OrderStatus]: string } = {
  'Pending Approval': 'قيد الموافقة',
  'Approved - Pending Payment': 'موافقة - في انتظار الدفع',
  'Payment Expired': 'انتهت صلاحية الدفع',
  'Declined': 'مرفوض',
  'Confirmed': 'مؤكد'
};

export function translateOrderStatus(status: OrderStatus): string {
  return orderStatusTranslations[status];
}