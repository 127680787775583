import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CONSTANTS } from 'data/constants';
import { fetchUserResponse, SignInResponse, User } from 'types/auth';

export interface UserState {
    signedIn: boolean;
    details: User | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: UserState = {
    signedIn: false,
    details: null,
    status: 'idle',
    error: null,
};



// Async thunk to fetch user data
export const fetchUser = createAsyncThunk<fetchUserResponse>('user/fetchUser', async () => {
    const response = await fetch(`${CONSTANTS.backendBaseURL}auth/me`, {
        method: 'GET',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Failed to fetch user data');
    }
    const data = await response.json();
    return data;
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signInUser: (state, action: PayloadAction<SignInResponse>) => {
            state.signedIn = true;
            state.details = action.payload.user;
        },
        signOutUser: (state) => {
            state.signedIn = false;
            state.details = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.signedIn = true;
                state.details = action.payload.user;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = 'failed';
                state.signedIn = false;
                state.details = null;
                state.error = action.error.message || null;
            });
    },
});

export const { signInUser, signOutUser } = userSlice.actions;

export default userSlice.reducer;
