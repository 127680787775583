import DatePicker from "react-datepicker";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { FC, Fragment, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "./DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "./DatePickerCustomDay";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import DatesRangeInput from "./HeroSearchForm2Mobile/DatesRangeInput";

interface ModalSelectReservationDetailsProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
}

const ModalSelectReservationDetails: FC<ModalSelectReservationDetailsProps> = ({ renderChildren }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const capacity = useSelector((state: RootState) => state.venueSearch.capacity);
  const date = useSelector((state: RootState) => state.venueSearch.date);

  //
  const [fieldNameShow, setFieldNameShow] = useState<
    "dates" | "guests"
  >("dates");
  //


  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>Select Date</button>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">{t('filterVenues.when')}</span>
            <span>
              {startDate ? date : t('filterVenues.addDate')}
            </span>
          </button>
        ) : (
          <DatesRangeInput />
        )}
      </div>
    );
  };

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>
                    <div className="flex-1 pt-7 px-1.5 sm:px-4 flex overflow-hidden">
                    <Tab.Group manual >
                      <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                        <Tab.Panel>
                          <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                            {renderInputDates()}
                          </div>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>

                    </div>

                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalSelectReservationDetails;
