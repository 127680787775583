import React from "react";
import { ThreeDots } from "react-loader-spinner";

interface DefaultLoadingProps {
    colour?: string;
}

const DefaultLoading: React.FC<DefaultLoadingProps> = ({ colour = "white" }) => {
    return (
        <ThreeDots
            height={25}
            width={30}
            color={colour}
            ariaLabel="rings-loading"
        />
    );
};

export default DefaultLoading;