import React, { FC, useEffect } from "react";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalGuidelines, setAdditionalGuidelinesArabic, setCheckoutGuidelines, setCheckoutGuidelinesArabic, setDescription } from "state/addVenueSlice";
import Checkbox from "shared/Checkbox/Checkbox";
import { RootState } from "state/store";

export interface PageAddListing6Props { }

const PageAddListing6: FC<PageAddListing6Props> = () => {
  //warns data will be lost before reload or exit
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const dispatch = useDispatch();
  const checkoutGuidelines = useSelector((state: RootState) => state.addVenue.postVenueNoImages.checkout_guidelines);
  const checkoutGuidelinesArabic = useSelector((state: RootState) => state.addVenue.postVenueNoImages.checkout_guidelines_ar);
  const additionalGuidelines = useSelector((state: RootState) => state.addVenue.postVenueNoImages.additional_guidelines);
  const additionalGuidelinesArabic = useSelector((state: RootState) => state.addVenue.postVenueNoImages.additional_guidelines_ar);
  return (
    <CommonLayout
      index="06"
      backtHref="/add-listing-5"
      nextHref="/add-listing-7"
    >
      <>
        <div>
          <h2 className="text-xl font-semibold">Things to know</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Checkout guidelines, additional guidelines
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <form className="space-y-6">
          {/* ITEM */}
          <div>
            <h2 className="text-xl font-semibold">
              Checkout Guidelines in English
            </h2>
            <p className="text-sm text-gray-500 mb-4">Example: Please ensure all decorations are removed by the end of the agreed rental period.</p>
            <Textarea
              onChange={(v) => dispatch(setCheckoutGuidelines(v.target.value))}
              value={checkoutGuidelines}
              placeholder=""
              rows={4}
            />
          </div>


          {/* ITEM */}
          <div>
            <h2 className="text-xl font-semibold">
              Checkout Guidelines in Arabic
            </h2>
            <Textarea
              onChange={(v) => dispatch(setCheckoutGuidelinesArabic(v.target.value))}
              value={checkoutGuidelinesArabic}
              placeholder=""
              rows={4}
            />
          </div>

          {/* ITEM */}
          <div>
            <h2 className="text-xl font-semibold">
              Additional Guidelines in English
            </h2>
            <p className="text-sm text-gray-500 mb-4">Example: We provide catering services upon request. Outside vendors are subject to approval by management.</p>
            <Textarea
              onChange={(v) => dispatch(setAdditionalGuidelines(v.target.value))}
              value={additionalGuidelines}
              placeholder=""
              rows={4}
            />
          </div>


          {/* ITEM */}
          <div>
            <h2 className="text-xl font-semibold">
              Additional Guidelines in Arabic
            </h2>
            <Textarea
              onChange={(v) => dispatch(setAdditionalGuidelinesArabic(v.target.value))}
              value={additionalGuidelinesArabic}
              placeholder=""
              rows={4}
            />
          </div>
        </form>
      </>
    </CommonLayout>
  );
};

export default PageAddListing6;
