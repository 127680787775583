import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { RootState } from "state/store";

interface AddVenueButtonProps {
    className?: string;
}

const AddVenueButton: FC<AddVenueButtonProps> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <Link to={'/add-listing-1'}>
            <ButtonPrimary className={className}>
                {t('navigation.addAVenue')}
            </ButtonPrimary>
        </Link>
    );
};

export default AddVenueButton;
