import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

export interface PageTermsAndConditionsProps {
    className?: string;
}

const PageTermsAndConditions: FC<PageTermsAndConditionsProps> = ({ className = "" }) => {
    const { t } = useTranslation();

    return (
        <div className={`nc-PageTermsAndConditions  ${className}`} data-nc-id="PageTermsAndConditions">
            <HelmetProvider>
                <Helmet>
                    <title>{t('footer.termsAndConditions')}</title>
                </Helmet>
            </HelmetProvider>       
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    {t('footer.termsAndConditions')}
                </h2>
                <div className="max-w-lg mx-auto space-y-6 ">
                    <ol className="list-decimal">
                        <li>{t('termsAndConditionsContent.p1')}</li>
                        <br />
                        <li>{t('termsAndConditionsContent.p2')}</li>
                        <br />
                        <li>{t('termsAndConditionsContent.p3')}</li>
                        <br />
                        <li>{t('termsAndConditionsContent.p4')}</li>
                        <br />

                    </ol>
                </div>
            </div>
        </div >
    );
};

export default PageTermsAndConditions;
