import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { setCapacity, setReservationMaxDaysAhead, setEarliestCheckInHour, setLatestCheckOutHour } from "state/addVenueSlice";
import { RootState } from "state/store";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface PageAddListing3Props { }

const PageAddListing3: FC<PageAddListing3Props> = () => {
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const dispatch = useDispatch();
  const capacity = useSelector((state: RootState) => state.addVenue.postVenueNoImages.capacity);
  const reservationMaxDaysAhead = useSelector((state: RootState) => state.addVenue.postVenueNoImages.reservation_max_days_ahead);
  const earliestCheckInHour = useSelector((state: RootState) => state.addVenue.postVenueNoImages.earliest_check_in_hour);
  const latestCheckOutHour = useSelector((state: RootState) => state.addVenue.postVenueNoImages.latest_check_out_hour);

  const [checkInError, setCheckInError] = useState<string | null>(null);

  const handleCheckInChange = (value: string) => {
    console.log(value);
    dispatch(setEarliestCheckInHour(value));
    if (value >= latestCheckOutHour) {
      setCheckInError("Check-in time must be before check-out time");
    } else {
      setCheckInError(null);
    }
  };

  const handleCheckOutChange = (value: string) => {
    dispatch(setLatestCheckOutHour(value));
    if (earliestCheckInHour >= value) {
      setCheckInError("Check-out time must be after check-in time");
    } else {
      setCheckInError(null);
    }
  };

  return (
    <CommonLayout
      index="03"
      backtHref="/add-listing-2"
      nextHref="/add-listing-4"
      isNextButtonDisabled={checkInError === null ? false : true}
    >
      <>
        <h2 className="text-2xl font-semibold">Booking limitations</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <FormItem label="Venue Capacity" desc="Number of guests your venue can hold at the same time">
            <Input
              type="number"
              onChange={(v) => dispatch(setCapacity(parseInt(v.target.value)))}
              value={capacity} // Set the value from the state
            />
          </FormItem>
          <FormItem label="Reservation max days ahead" desc="How many days in advance do people have to reserve your venue">
            <Input
              type="number"
              onChange={(v) => dispatch(setReservationMaxDaysAhead(parseInt(v.target.value)))}
              value={reservationMaxDaysAhead} // Set the value from the state
            />
          </FormItem>
          <FormItem label="Earliest Check-In Time" desc="Earliest time guests can check in">
            <Input
              type="time"
              onChange={(v) => handleCheckInChange(v.target.value)}
              value={earliestCheckInHour} // Set the value from the state
            />
            {checkInError && <p className="text-red-500">{checkInError}</p>}
          </FormItem>
          <FormItem label="Latest Check-Out Hour" desc="Latest time guests can check out">
            <Input
              type="time"
              onChange={(v) => handleCheckOutChange(v.target.value)}
              value={latestCheckOutHour} // Set the value from the state
            />
            {checkInError && <p className="text-red-500">{checkInError}</p>}
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing3;
