import React, { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { CONSTANTS } from "data/constants";
import { useDispatch, useSelector } from "react-redux";
import { setBudget, setListedVenue } from "state/addVenueSlice";
import { RootState } from "state/store";
import { useListVenueMutation } from "state/api/apiSlice";
import { useTranslation } from "react-i18next";

export interface PageAddListing8Props { }

const PageAddListing8: FC<PageAddListing8Props> = () => {
  const percentageDeducted = 3;
  const venue_type = useSelector((state: RootState) => state.addVenue.postVenueNoImages.type);
  const provider_id = useSelector((state: RootState) => state.user.details?.id);
  const venue = useSelector((state: RootState) => state.addVenue.postVenueNoImages);
  const budget = useSelector((state: RootState) => state.addVenue.postVenueNoImages.budget);
  const dispatch = useDispatch();
  const [listVenue] = useListVenueMutation();

  const runBeforeNext = async () => {
    try {
      const listedVenue = await listVenue({ venue, venueType: venue_type, userId: provider_id ?? -1 }).unwrap();
      console.log(listVenue);
      dispatch(setListedVenue(listedVenue.venue));
    } catch (error) {
      console.error("Error listing venue:", error);
      throw error;
    }
  };
  const { t } = useTranslation();

  return (
    <CommonLayout
      index="08"
      backtHref="/add-listing-7"
      nextHref="/add-listing-9"
      runBeforeNext={runBeforeNext}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Price your space</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            The host's revenue is directly dependent on the setting of rates and
            regulations on the number of guests, the number of nights, and the
            cancellation policy.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label={`Base price per ${venue_type === 'wedding_hall' ? 'day' : 'hour'}`}>
            <div className="relative">
              <Input
                onChange={(v) => {
                  if (v.target.value !== '') {
                    dispatch(setBudget(parseFloat(v.target.value)))
                  } else {
                    dispatch(setBudget(0))
                  }
                }}
                className="!pr-10"
                // placeholder="0.00"
                value={budget} // Set the value from the state
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">{t('currency.sar')}</span>
              </div>
            </div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              {`You make ${budget * (1 - (3 / 100))} ${t('currency.sar')} we take a ${percentageDeducted}% commission`}
            </span>
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing8;
