import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const AuthenticationButtons: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Link to={'/login'}>
        <ButtonPrimary>{t('auth.login')}</ButtonPrimary>
      </Link>
      <div className="px-1" />
      <Link to={'/signup'}>
        <ButtonPrimary className="bg-white text-primary-800 hover:bg-secondary-200">{t('auth.signup')}</ButtonPrimary>
      </Link>
    </>
  );
};

export default AuthenticationButtons;

