import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coordinates, Feature, Occasion, PostVenueType, Venue } from 'types/venue';
import dataEnglish from 'i18n/en.json';
import dataArabic from 'i18n/ar.json';
import { VenueTypes } from 'components/HeroSearchForm/type';

interface AddVenueState {
    postVenueNoImages: PostVenueType;
    listedVenue: Venue | null;
}


const initialState: AddVenueState = {
    postVenueNoImages: {
        additional_guidelines: '',
        additional_guidelines_ar: '',
        budget: 0,
        capacity: 0,
        checkout_guidelines: '',
        checkout_guidelines_ar: '',
        city: dataEnglish.cities.alRiyadh,
        city_ar: dataArabic.cities.alRiyadh,
        description: '',
        description_ar: '',
        features: [],
        name: '',
        name_ar: '',
        neighborhood: '',
        neighborhood_ar: '',
        occasions: [],
        options: [],
        region: '',
        region_ar: '',
        reservation_conditions: [],
        reservation_max_days_ahead: 0,
        star_rating: 0,
        street: '',
        street_ar: '',
        type: 'wedding_hall',
        earliest_check_in_hour: '00:00',
        latest_check_out_hour: '23:59',
        coordinates: {
            longitude: -1,
            latitude: -1,
        },
        country: dataEnglish.countries.saudiArabia,
        country_ar: dataArabic.countries.saudiArabia,
    },
    listedVenue: null,
};

let nextFeatureId = 1;

export const addVenueSlice = createSlice({
    name: 'addVenue',
    initialState,
    reducers: {
        setVenueType: (state, action: PayloadAction<VenueTypes>) => {
            state.postVenueNoImages.type = action.payload;
        },
        setName: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.name = action.payload;
        },
        setNameArabic: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.name_ar = action.payload;
        },
        setCoordinates: (state, action: PayloadAction<Coordinates>) => {
            state.postVenueNoImages.coordinates = action.payload;
        },
        setCity: (state, action: PayloadAction<{ city_ar: string, city: string }>) => {
            state.postVenueNoImages.city = action.payload.city;
            state.postVenueNoImages.city_ar = action.payload.city_ar;
        },
        setStreet: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.street = action.payload;
        },
        setStreetArabic: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.street_ar = action.payload;
        },
        setCapacity: (state, action: PayloadAction<number>) => {
            state.postVenueNoImages.capacity = action.payload;
        },
        setReservationMaxDaysAhead: (state, action: PayloadAction<number>) => {
            state.postVenueNoImages.reservation_max_days_ahead = action.payload;
        },
        setDescription: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.description = action.payload;
        },
        setDescriptionArabic: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.description_ar = action.payload;
        },
        setBudget: (state, action: PayloadAction<number>) => {
            state.postVenueNoImages.budget = action.payload;
        },
        setOccasions: (state, action: PayloadAction<number[]>) => {
            state.postVenueNoImages.occasions = action.payload;
        },
        setOptions: (state, action: PayloadAction<number[]>) => {
            state.postVenueNoImages.options = action.payload;
        },
        setListedVenue: (state, action: PayloadAction<Venue>) => {
            state.listedVenue = action.payload;
        },
        addFeature: (state, action: PayloadAction<Omit<Feature, 'id'>>) => {
            state.postVenueNoImages.features.push({ ...action.payload, id: nextFeatureId++ });
        },
        updateFeature: (state, action: PayloadAction<Feature>) => {
            const index = state.postVenueNoImages.features.findIndex(f => f.id === action.payload.id);
            if (index !== -1) {
                state.postVenueNoImages.features[index] = action.payload;
            }
        },
        deleteFeature: (state, action: PayloadAction<number>) => {
            state.postVenueNoImages.features = state.postVenueNoImages.features.filter(f => f.id !== action.payload);
        },
        setCheckoutGuidelines: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.checkout_guidelines = action.payload;
        },
        setCheckoutGuidelinesArabic: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.checkout_guidelines_ar = action.payload;
        },
        setAdditionalGuidelines: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.additional_guidelines = action.payload;
        },
        setAdditionalGuidelinesArabic: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.additional_guidelines_ar = action.payload;
        },
        setEarliestCheckInHour: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.earliest_check_in_hour = action.payload;
        },
        setLatestCheckOutHour: (state, action: PayloadAction<string>) => {
            state.postVenueNoImages.latest_check_out_hour = action.payload;
        },
    },
});

export const {
    setVenueType,
    setName,
    setNameArabic,
    setCoordinates,
    setCity,
    setStreet,
    setStreetArabic,
    setCapacity,
    setReservationMaxDaysAhead,
    setDescription,
    setDescriptionArabic,
    setBudget,
    setOccasions,
    setOptions,
    setListedVenue,
    addFeature,
    updateFeature,
    deleteFeature,
    setCheckoutGuidelines,
    setCheckoutGuidelinesArabic,
    setAdditionalGuidelines,
    setAdditionalGuidelinesArabic,
    setEarliestCheckInHour,
    setLatestCheckOutHour
} = addVenueSlice.actions;

export default addVenueSlice.reducer;
