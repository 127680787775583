import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VenueTypes } from 'components/HeroSearchForm/type';

export interface VenueSearchParameters {
    type: VenueTypes;
    location: string;
    date: string | undefined;
    capacity: number;
}

const initialState: VenueSearchParameters = {
    type: 'wedding_hall',
    location: '',
    date: new Date().toISOString().split('T')[0],
    capacity: 0,
}

export const venueSearchSlice = createSlice({
    name: 'venueSearch',
    initialState,
    reducers: {
        setSearchLocation: (state, action: PayloadAction<string>) => {
            state.location = action.payload
        },
        setSearchType: (state, action: PayloadAction<VenueTypes>) => {
            state.type = action.payload
        },
        setSearchDate: (state, action: PayloadAction<string | undefined>) => {
            state.date = action.payload
            console.log(state.date);
        },
        setSearchCapacity: (state, action: PayloadAction<number>) => {
            state.capacity = action.payload
        },
        clearSearchParameters: (state) => {
            state.location = '';
            state.date = new Date().toISOString().split('T')[0];
            state.capacity = 0;
        }

    },
})

// Action creators are generated for each case reducer function
export const { setSearchLocation, setSearchType, setSearchDate, setSearchCapacity, clearSearchParameters } = venueSearchSlice.actions

export default venueSearchSlice.reducer