import React, { FC, useState } from 'react';
import { useAddVerificationDocumentsMutation } from 'state/api/apiSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import DefaultLoading from 'shared/Loading/DefaultLoading';

export interface PageVerificationDocumentsProps {
    className?: string;
}

const PageVerificationDocuments: FC<PageVerificationDocumentsProps> = ({ className = "" }) => {
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [addVerificationDocuments, { isLoading }] = useAddVerificationDocumentsMutation();
    const providerId = useSelector((state: RootState) => state.user.details?.id);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFiles([...selectedFiles, ...Array.from(event.target.files)]);
        }
    };

    const handleRemoveFile = (index: number) => {
        const newFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(newFiles);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (selectedFiles.length === 0) {
            alert(t('auth.errors.pleaseSelectAtLeastOneFile'));
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file, index) => {
            formData.append(`document${index}`, file);
        });

        try {
            await addVerificationDocuments({ providerId: providerId ?? -1, formData: formData }).unwrap();
            alert(t('auth.filesUploadedSuccessfully'));
            setSelectedFiles([]);
        } catch (error) {
            alert(t('auth.errors.fileUploadFailed'));
        }
    };

    return (
        <div className={`nc-PageVerificationDocuments ${className}`} data-nc-id="PageVerificationDocuments">
            <HelmetProvider>
                <Helmet>
                    <title>{t('auth.signup')}</title>
                </Helmet>
            </HelmetProvider>  
            <div className="max-w-xl mx-auto my-8 p-4 bg-white shadow-md rounded">
                <h2 className="text-2xl font-bold mb-4">{t('auth.uploadVerficationDocuments')}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            {t('auth.selectFiles')}
                        </label>
                        <div className="relative">
                            <label className="block w-full py-2 px-4 text-center bg-secondary-400 text-black rounded-lg cursor-pointer hover:bg-secondary-500">
                                {t('auth.browseFiles')}
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    multiple
                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="mb-4">
                        {selectedFiles.map((file, index) => (
                            <div
                                key={index}
                                className="flex flex-wrap justify-between items-center p-2 mb-2 bg-gray-100 rounded"
                            >
                                <span className="text-gray-700 truncate w-full sm:w-auto">{file.name}</span>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveFile(index)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    {t('auth.remove')}
                                </button>
                            </div>
                        ))}
                    </div>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full py-2 px-4 rounded ${isLoading ? 'bg-gray-500' : 'bg-primary-700 hover:bg-primary-800'} text-white font-bold flex justify-center items-center`}
                    >
                        {isLoading ? <DefaultLoading /> : t('auth.upload')}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default PageVerificationDocuments;
