import React, { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { useGetFilteredVenuesQuery } from "state/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { VenueFilterParametersType } from "types/venue";
import DefaultLoading from "shared/Loading/DefaultLoading";
import { useTranslation } from "react-i18next";

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const { location, capacity, type, date } = useSelector((state: RootState) => state.venueSearch)
  const venueSearchParameters: VenueFilterParametersType = {
    city: location,
    capacity,
    type,
    date,
  }
  const { data, error, isLoading } = useGetFilteredVenuesQuery(venueSearchParameters);
  const { t } = useTranslation();
  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <HelmetProvider>
        <Helmet>
          <title>{t('navigation.venues')}</title>
        </Helmet>
      </HelmetProvider>

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <HeroSearchForm currentTab={type} className="pt-10 pb-24 lg:pb-28 lg:pt-16 " />

        {/* SECTION */}
        {isLoading ? <DefaultLoading colour="purple" /> : data && <SectionGridFilterCard className="pb-24 lg:pb-28" data={data.venues} />}
      </div>
    </div>
  );
};

export default ListingStayPage;
