import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { setSearchCapacity } from "state/venueSearchSlice";
export interface GuestsInputProps {
  defaultValue?: GuestsObject;
  onChange?: (data: GuestsObject) => void;
  className?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  className = "",
}) => {
  const { t } = useTranslation();
  const capacity = useSelector((state: RootState) => state.venueSearch.capacity);
  const dispatch = useDispatch();

  const handleChangeData = (value: number) => {
    dispatch(setSearchCapacity(value));
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {t('filterVenues.numberOfPeopleComing')}
      </span>

      <NcInputNumber
        className="w-full"
        defaultValue={capacity}
        onChange={(value) => handleChangeData(value)}
        min={1}
        label={t('filterVenues.guests')}
        desc={t('filterVenues.numberOfPeopleComing')}
      />

    </div>
  );
};

export default GuestsInput;
