import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { setOptions } from "state/addVenueSlice";
import { useGetVenueOptionsQuery } from "state/api/apiSlice";
import DefaultLoading from "shared/Loading/DefaultLoading";

export interface VenueOptionsCheckListProps { }

const VenueOptionsCheckList: FC<VenueOptionsCheckListProps> = () => {
    const { data } = useGetVenueOptionsQuery();
    const dispatch = useDispatch();
    const selectedOptions = useSelector(
        (state: RootState) => state.addVenue.postVenueNoImages.options
    );

    const handleCheckboxChange = (itemId: number) => {
        const updatedOptions = selectedOptions.includes(itemId)
            ? selectedOptions.filter((id) => id !== itemId)
            : [...selectedOptions, itemId];
        dispatch(setOptions(updatedOptions));
    };

    const handleImageClick = (itemId: number) => {
        const checkbox = document.getElementById(
            `venue-option-${itemId}`
        ) as HTMLInputElement;
        checkbox.checked = !checkbox.checked;
        handleCheckboxChange(itemId);
    };

    return (
        <div>
            <h2 className="text-2xl font-semibold">Select allowed arrangments</h2>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                {data ? (
                    data.venue_options.map((item) => (
                        <div key={item.id} className="flex items-center">
                            <div
                                className="relative"
                                onClick={() => handleImageClick(item.id)}
                            >
                                <img
                                    src={item.image_url}
                                    alt={item.name}
                                    className="w-20 h-20 object-cover rounded-lg"
                                />
                                <input
                                    type="checkbox"
                                    id={`venue-option-${item.id}`}
                                    checked={selectedOptions.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                    className="form-checkbox absolute bottom-0 right-0 h-5 w-5 text-indigo-600"
                                />
                            </div>
                            <label
                                htmlFor={`venue-option-${item.id}`}
                                className="ml-2 block text-sm font-medium text-gray-700"
                            >
                                {item.name}
                            </label>
                        </div>
                    ))
                ) : (
                    <DefaultLoading />
                )}
            </div>
        </div>
    );
};

export default VenueOptionsCheckList;