import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEN from './en.json';
import translationsAR from './ar.json';

const resources = {
    en: {
        translation: translationsEN,
    },
    ar: {
        translation: translationsAR,
    },
};

const storedLanguage = localStorage.getItem('language') || 'ar';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        lng: storedLanguage, // set initial language
        fallbackLng: 'ar', // default language
        keySeparator: '.', // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

console.log('Detected Language:', i18n.language);

export default i18n;
