import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateOrderRequest, Order } from 'types/order';
import { Venue } from 'types/venue';



const initialState: { newOrderParameters: CreateOrderRequest; newOrder: Order | null; orderVenue: Venue | null; } = {
    newOrderParameters: {},
    newOrder: null,
    orderVenue: null,
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setNewOrderFromDate: (state, action: PayloadAction<string>) => {
            state.newOrderParameters.from_date = action.payload;
        },
        setNewOrderToDate: (state, action: PayloadAction<string>) => {
            state.newOrderParameters.to_date = action.payload;
        },
        setNewOrderCapacity: (state, action: PayloadAction<number>) => {
            state.newOrderParameters.capacity = action.payload;
        },
        setNewOrderVenueId: (state, action: PayloadAction<number>) => {
            state.newOrderParameters.venue_id = action.payload;
        },
        setNewOrder: (state, action: PayloadAction<Order>) => {
            state.newOrder = action.payload;
        },
        setOrderVenue: (state, action: PayloadAction<Venue>) => {
            state.orderVenue = action.payload;
        },

    },
})

export const { setNewOrderFromDate, setNewOrderToDate, setNewOrderCapacity, setNewOrderVenueId, setNewOrder, setOrderVenue } = orderSlice.actions

export default orderSlice.reducer