import React, { FC, useRef } from "react";
import 'react-phone-number-input/style.css'

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomerRegisterRequest } from "types/auth";
import CustomInput from "shared/CustomInput/CustomInput";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DefaultLoading from "shared/Loading/DefaultLoading";
import { useCustomerRegisterMutation } from "state/api/apiSlice";
import { useDispatch } from "react-redux";
import { signInUser } from "state/userSlice";
import { useNavigate } from "react-router-dom";
import { emailRegex } from "utils/validators";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from 'react-phone-number-input'

interface CustomerRegisterFormProps {
    className?: string;
}

interface CustomerRegisterForm extends CustomerRegisterRequest {
    repeat_password: string;
}



const CustomerRegisterForm: FC<CustomerRegisterFormProps> = ({
    className = "",
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const router = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm<CustomerRegisterForm>();

    const [customerRegister, { isLoading, error }] = useCustomerRegisterMutation();

    const onSubmit: SubmitHandler<CustomerRegisterRequest> = async (data) => {
        const credentials: CustomerRegisterRequest = {
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            password: data.password,
            phone_number: data.phone_number,
            //could refactor
            gender: 'not specified'
        };
        const response = await customerRegister(credentials);

        if ('data' in response) {
            dispatch(signInUser(response.data!))
            router('/')
        }
    };

    const password = useRef({});
    password.current = watch("password", "");

    return (
        <form className={`grid grid-cols-1 gap-6 ${className}`} onSubmit={handleSubmit(onSubmit)}>
            <CustomInput label={t('auth.firstName')} register={register('first_name', { required: t('auth.errors.thisFieldIsRequired') })} />
            {errors.first_name && <p className="text-red-500">{errors.first_name.message}</p>}

            <CustomInput label={t('auth.lastName')} register={register('last_name', {
                required: t('auth.errors.thisFieldIsRequired'),
            })} />
            {errors.last_name && <p className="text-red-500">{errors.last_name?.message}</p>}

            <CustomInput label={t('auth.emailAddress')} register={register('email', {
                required: t('auth.errors.thisFieldIsRequired'),
                pattern: {
                    value: emailRegex,
                    message: t('auth.errors.emailFormatInvalid')
                }
            })} />
            {errors.email && <p className="text-red-500">{errors.email?.message}</p>}

            <span className="text-neutral-800 dark:text-neutral-200">
                {t('auth.phoneNumber')}
            </span>
            <Controller
                name="phone_number"
                control={control}
                rules={{
                    required: t('auth.errors.thisFieldIsRequired')
                }}
                render={({ field: { onChange, value } }) => (
                    <PhoneInput
                        value={value}
                        onChange={onChange}
                        defaultCountry="SA"
                        id="phone_number"

                    />
                )}
            />
            {errors.phone_number && <p className="text-red-500">{errors.phone_number?.message}</p>}



            <CustomInput type="password" label={t('auth.password')} register={register('password', {
                required: t('auth.errors.thisFieldIsRequired'), minLength: {
                    value: 8,
                    message: t('auth.errors.passwordMustBeAtleast', { minimumLength: 8 })
                }
            })} />
            {errors.password && <p className="text-red-500">{errors.password?.message}</p>}

            <CustomInput type="password" label={t('auth.repeatPassword')} register={register('repeat_password', {
                required: t('auth.errors.thisFieldIsRequired'),
                validate: value => value === password.current || t('auth.errors.passwordsDoNotMatch')
            })} />
            {errors.repeat_password && <p className="text-red-500">{errors.repeat_password?.message}</p>}

            {error && <p className="text-red-500">{t('auth.errors.thereIsAlreadyAnAccount')}</p>}
            <ButtonPrimary type="submit">
                {isLoading ? <DefaultLoading /> : t('auth.continue')}
            </ButtonPrimary>
        </form>
    );
};

export default CustomerRegisterForm;
