import React, { FC, useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectReservationDetails from "components/ModalSelectReservationDetails";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Venue } from "types/venue";
import { useDispatch, useSelector } from "react-redux";
import { setNewOrder, setNewOrderVenueId, setOrderVenue } from "state/orderSlice";
import { RootState } from "state/store";
import { useCreateOrderMutation } from "state/api/apiSlice";
import { useNavigate } from "react-router-dom";

interface MobileFooterStickyProps{
  venue: Venue;
}

const MobileFooterSticky : FC<MobileFooterStickyProps> = ({venue}) => {
  const [startDate] = useState<Date | null>(null);
  const [endDate] = useState<Date | null>(null);
  const capacity = useSelector((state: RootState) => state.order.newOrderParameters.capacity);
  const newOrderParameters = useSelector((state: RootState) => state.order.newOrderParameters);
  const [createOrder, { isLoading, error }] = useCreateOrderMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const onReserveClick = async () => {
    dispatch(setNewOrderVenueId(venue.id));
    console.log('aa')
    if (startDate && endDate && capacity && newOrderParameters.venue_id) {
        const response = await createOrder(newOrderParameters);

        if ('data' in response) {
            dispatch(setNewOrder(response.data!.order));
            dispatch(setOrderVenue(response.data!.venue));
            navigate(`/order-status/${response.data!.order.id}`);
        }
    }
};

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            $311
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span>
          </span>
          <ModalSelectReservationDetails
            renderChildren={({ openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {/* {converSelectedDateToString([startDate, endDate])} */}
                FIGURE THIS OUT
              </span>
            )}
          />
        </div>
          <ButtonPrimary
            sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
            onClick={onReserveClick}
          >
            Reserve
          </ButtonPrimary>
      </div>
    </div>
  );
};

export default MobileFooterSticky;
