import React, { FC, useState } from "react";
import Heading from "components/Heading/Heading";
import { useTranslation } from "react-i18next";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}


export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const FOUNDER_DEMO: Statistic[] = [
    {
      id: "1",
      heading: t('aboutUsPage.section2.reason1Title'),
      subHeading:
        t('aboutUsPage.section2.reason1'),
    },
    {
      id: "2",
      heading: t('aboutUsPage.section2.reason2Title'),
      subHeading: t('aboutUsPage.section2.reason2'),
    },
    {
      id: "3",
      heading: t('aboutUsPage.section2.reason3Title'),
      subHeading:
        t('aboutUsPage.section2.reason3'),
    },
  ];

  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading isCenter={true} desc="">
        {t('aboutUsPage.section2.title')}
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 hover:bg-secondary-200 rounded-2xl dark:border-neutral-800 transition-all duration-400 ease-in-out"

          >
            <h3 className={`text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200`}>
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;
