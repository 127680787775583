import React, { FC, useEffect } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useTranslation } from "react-i18next";
import data from 'i18n/en.json';
import { useDispatch, useSelector } from "react-redux";
import { setName, setNameArabic, setVenueType } from "state/addVenueSlice";
import { VenueTypes } from "components/HeroSearchForm/type";
import { RootState } from "state/store";

export interface PageAddListing1Props { }

const PageAddListing1: FC<PageAddListing1Props> = () => {
  //warns data will be lost before reload or exit
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const { t } = useTranslation();
  const venueTypes = Object.keys(data.venueTypes);

  const dispatch = useDispatch();
  const venueType = useSelector((state: RootState) => state.addVenue.postVenueNoImages.type);
  const name = useSelector((state: RootState) => state.addVenue.postVenueNoImages.name);
  const nameArabic = useSelector((state: RootState) => state.addVenue.postVenueNoImages.name_ar);


  const onTypeChange = (v: React.ChangeEvent<HTMLSelectElement>) => {
    const type = v.target.value as VenueTypes;
    dispatch(setVenueType(type));
  }


  return (
    <CommonLayout
      index="01"
      backtHref="/add-listing-1"
      nextHref="/add-listing-2"
    >
      <>
        <h2 className="text-2xl font-semibold">{t('venueDetails.chooseVenueCategory')}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label={t('venueDetails.chooseVenueType')}
          >
            <Select onChange={(v) => onTypeChange(v)} value={venueType}>
              {
                venueTypes.map((type) => {
                  return (
                    <option key={type} value={type}>{t(`venueTypes.${type}`)}</option>
                  )
                })
              }
            </Select>
          </FormItem>
          <FormItem
            label={t('venueDetails.venueNameInEnglish')}
            desc={t('venueDetails.thisWillBeTitleEnglish')}
          >
            <Input
              placeholder={t('venueDetails.venueName')}
              onChange={(v) => dispatch(setName(v.target.value))}
              value={name} // Set the value from the state
            />
          </FormItem>
          <FormItem
            label={t('venueDetails.venueNameInArabic')}
            desc={t('venueDetails.thisWillBeTitleArabic')}
          >
            <Input
              placeholder={t('venueDetails.venueName')}
              onChange={(v) => dispatch(setNameArabic(v.target.value))}
              value={nameArabic} // Set the value from the state
            />
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );

};

export default PageAddListing1;
