import { FC, useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from 'react-i18next';
import { useRequestResetPasswordMutation, useResetPasswordMutation } from "state/api/apiSlice";
import { RequestResetPasswordRequest, ResetPasswordRequest, SignInRequest } from "types/auth";
import { emailRegex } from "utils/validators";
import DefaultLoading from "shared/Loading/DefaultLoading";
import CustomInput from "shared/CustomInput/CustomInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

export interface PageResetPasswordProps {
    className?: string;
}

interface FormData {
    new_password: string;
    repeat_new_password: string;
}


const PageResetPassword: FC<PageResetPasswordProps> = ({ className = "" }) => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        setToken(token ?? '');
    }, []);

    const { t } = useTranslation();
    const router = useNavigate();
    const [token, setToken] = useState('');
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);


    //rtk query
    const [resetPassword, { isLoading, error }] = useResetPasswordMutation();
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>()

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
        const body: ResetPasswordRequest = {
            new_password: formData.new_password
        }
        const response = await resetPassword({ token, body });
        if ('data' in response) {
            console.log(response)
            setPasswordResetSuccess(true);
        }
    }
    const password = useRef({});
    password.current = watch("new_password", "");
    return (
        <div className={`nc-PageRequestResetPassword ${className}`} data-nc-id="PageRequestResetPassword">
            <HelmetProvider>
                <Helmet>
                    <title>{t('auth.resetPassword')}</title>
                </Helmet>
            </HelmetProvider>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    {t('auth.resetPassword')}
                </h2>
                <div className="max-w-md mx-auto space-y-6 ">
                    <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>

                        <CustomInput type="password" label={t('auth.newPassword')} register={register('new_password', {
                            required: t('auth.errors.thisFieldIsRequired'), minLength: {
                                value: 8,
                                message: t('auth.errors.passwordMustBeAtleast', { minimumLength: 8 })
                            }
                        })} />
                        {errors.repeat_new_password && <p className="text-red-500">{errors.new_password?.message}</p>}

                        <CustomInput type="password" label={t('auth.repeatPassword')} register={register('repeat_new_password', {
                            required: t('auth.errors.thisFieldIsRequired'),
                            validate: value => value === password.current || t('auth.errors.passwordsDoNotMatch')
                        })} />
                        {errors.repeat_new_password && <p className="text-red-500">{errors.repeat_new_password?.message}</p>}

                        {passwordResetSuccess ?
                            <>
                                <p className="text-primary-800 text-center">
                                    {t('auth.passwordResetSuccess')}
                                </p>
                                <ButtonPrimary onClick={() => router('/login')}>
                                    {t('auth.login')}
                                </ButtonPrimary>
                            </>
                            :
                            <ButtonPrimary type="submit">
                                {isLoading ? <DefaultLoading /> : t('auth.continue')}
                            </ButtonPrimary>
                        }
                        {error && <p className="text-red-500 text-center">{t('auth.errors.linkInvalidOrExpired')}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PageResetPassword;
