import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

export interface PagePrivacyPolicyProps {
    className?: string;
}

const PagePrivacyPolicy: FC<PagePrivacyPolicyProps> = ({ className = "" }) => {
    const { t } = useTranslation();

    return (
        <div className={`nc-PagePrivacyPolicy  ${className}`} data-nc-id="PagePrivacyPolicy">
            <HelmetProvider>
                <Helmet>
                    <title>{t('footer.privacyPolicy')}</title>
                </Helmet>
            </HelmetProvider> 
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    {t('footer.privacyPolicy')}
                </h2>
                <div className="max-w-xlg mx-auto space-y-6 ">
                    <p>{t('privacyPolicyContent.intro')}</p>
                    <h2 className="font-semibold">{t('privacyPolicyContent.p1')}</h2>
                    <p>{t('privacyPolicyContent.p1Text')}</p>
                    <h2 className="font-semibold">{t('privacyPolicyContent.p2')}</h2>
                    <p>{t('privacyPolicyContent.p2Text')}</p>
                    <h2 className="font-semibold">{t('privacyPolicyContent.p3')}</h2>
                    <p>{t('privacyPolicyContent.p3Text')}</p>
                    <h2 className="font-semibold">{t('privacyPolicyContent.p4')}</h2>
                    <p>{t('privacyPolicyContent.p4Text')}</p>
                    <h2 className="font-semibold">{t('privacyPolicyContent.p5')}</h2>
                    <p>{t('privacyPolicyContent.p5Text')}</p>
                </div>
            </div>
        </div >
    );
};

export default PagePrivacyPolicy;
