import React, { FC, Fragment, useEffect, useState } from "react";

import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import DetailPagetLayout from "../Layout";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { useTranslation } from "react-i18next";
import { useGetVenueByIdQuery } from "state/api/apiSlice";
import DailySideBar from "./DailySideBar";
import { CONSTANTS } from "data/constants";
import HourlySideBar from "./HourlySideBar";

const StayDetailPageContainer: FC<{}> = () => {
  //
  const { id } = useParams<{ id: string }>();
  const { data: venueData, error, isLoading, refetch } = useGetVenueByIdQuery({ venue_id: Number(id) });
  const { t } = useTranslation();
  const userType = useSelector((state: RootState) => state.user.details?.type);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  useEffect(() => {
    refetch();
  }, [])
  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={t(`venueTypes.${venueData?.venue?.type}`)} />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {currentLanguage === 'en' ? venueData?.venue?.name : venueData?.venue.name_ar}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4 rtl:space-x-reverse">
          {!!venueData?.venue.star_rating && <StartRating point={venueData?.venue?.star_rating} />}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {currentLanguage === 'en' ? venueData?.venue?.city : venueData?.venue.city_ar}</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" imgUrl={venueData?.venue?.provider.profile_picture} />
          <span className="ms-2.5 text-neutral-500 dark:text-neutral-400">
            {`${t('venueDetails.hostedBy')} `}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {venueData?.venue?.provider.company_name}
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 rtl:space-x-reverse text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 rtl:space-x-reverse">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {venueData?.venue?.capacity} <span className="hidden sm:inline-block">{t('filterVenues.guests')}</span>
            </span>
          </div>

        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">{t('venueDetails.venueDescription')}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {currentLanguage === 'en' ? venueData?.venue?.description : venueData?.venue.description_ar}
          </span>
        </div>
        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">{t('venueDetails.features')}</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              {venueData?.venue.features?.map(feature => (
                <li key={feature.id}>{currentLanguage === 'en' ? feature.name : feature.name_ar}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {

    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{t('venueDetails.options')} </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t('venueDetails.arrangmentsOfSeatingsAllowed')}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
          {venueData?.venue.options.map((item) => {
            const name = currentLanguage === 'en' ? item.name : item.name_ar;
            return (
              <div key={item.id} className="flex items-center space-x-3 rtl:space-x-reverse">
                <img src={item.image_url} alt={name} className="h-12 w-12 object-cover" />
                <span>{name}</span>
              </div>
            )
          }
          )}
        </div>
      </div>
    );
  };

  const renderSection4 = () => {

    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{t('venueDetails.occasions')}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t('venueDetails.typesOfOccasionsAllowed')}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
          {venueData?.venue.occasions.map((item) => (
            <div key={item.id} className="flex items-center space-x-3 rtl:space-x-reverse">
              <img src={item.image_url} alt={item.name} className="h-12 w-12 object-cover" />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">{t('filterVenues.location')}</h2>
          {currentLanguage === 'en' ?
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              {venueData?.venue?.street}, {venueData?.venue?.city}, {venueData?.venue?.country}
            </span>
            :
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              {venueData?.venue?.street_ar}, {venueData?.venue?.city_ar}, {venueData?.venue?.country_ar}
            </span>
          }
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={venueData?.venue.map_url}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">{t('venueDetails.thingsToKnow')}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">{t('venueDetails.checkoutGuidelines')}</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {currentLanguage === 'en' ? venueData?.venue.checkout_guidelines : venueData?.venue.checkout_guidelines_ar}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">{t('venueDetails.additionalGuidelines')}</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {currentLanguage === 'en' ? venueData?.venue.additional_guidelines : venueData?.venue.additional_guidelines_ar}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">{t('venueDetails.checkInTime')}</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>{t('venueDetails.checkIn')}</span>
              <span>{venueData?.venue.earliest_check_in_hour}</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>{t('venueDetails.checkOut')}</span>
              <span>{venueData?.venue.latest_check_out_hour}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={venueData?.venue?.images[0].image_url}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {venueData?.venue?.images.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={item.image_url || ""}
                  alt=""
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center start-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ms-2 text-neutral-800 text-sm font-medium">
              {t('venueDetails.showAllPhotos')}
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pe-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderSection4()}
          {renderSection5()}
          {renderSection6()}
        </div>

        {/* SIDEBAR */}
        {userType !== CONSTANTS.userType.provider && <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">
            {venueData && venueData.venue.type === 'wedding_hall' && <DailySideBar venue={venueData.venue} />}
            {venueData && venueData.venue.type !== 'wedding_hall' && <HourlySideBar venue={venueData.venue} />}

          </div>
        </div>}
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
