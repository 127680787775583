import React, { FC, useEffect, useState } from "react";
import { useGetOrderByIdQuery, useGetVenueByIdQuery, useRefreshPaymentMutation } from "state/api/apiSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import DefaultLoading from "shared/Loading/DefaultLoading";
import { useTranslation } from "react-i18next";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshPayment, { isLoading: refreshLoading }] = useRefreshPaymentMutation();
  const [id, setId] = useState<number | null>(null);
  const { t } = useTranslation();
  const { data: orderData, error: orderError, isLoading: orderLoading } = useGetOrderByIdQuery(
    { order_id: id! },
    { skip: id === null } // Skip the query if id is null
  );

  const { data: venueData, error: venueError, isLoading: venueLoading } = useGetVenueByIdQuery(
    { venue_id: Number(orderData?.order.venue_id) },
    { skip: !orderData } // Skip the query if orderData is not yet available
  );

  const fetchPayment = async (orderId: number) => {
    setIsLoading(true);

    const response = await refreshPayment({ orderId });
    if ('data' in response) {
      console.log("Refresh payment success:", response);
    } else {
      console.error("Refresh payment error:", error);
      setError("An error occurred. Please check your internet connection.");
    }

    setIsLoading(false);
  };
  useEffect(() => {
    const currentUrl = window.location.href;
    const parsedUrl = new URL(currentUrl);
    const pathname = parsedUrl.pathname;
    const pathSegments = pathname.split('/');

    // Extract the second segment (index 2) which is id
    const orderId = Number(pathSegments[2]); // Assign id value
    setId(orderId);


    if (orderId) {
      fetchPayment(orderId);
    }
  }, [refreshPayment]);

  const handleRetry = async () => {
    if (id) { // Ensure id is defined before retrying
      setError(null); // Clear previous error
      fetchPayment(id); // Retry the payment refresh
    }
  };
  const renderContent = () => {
    if (isLoading || refreshLoading) {
      return <DefaultLoading />
    }

    if (error) {
      return (
        <div className="flex flex-col items-center justify-center space-y-4">
          <p>{error}</p>
          <ButtonPrimary onClick={handleRetry}>Retry</ButtonPrimary>
          <span>If this doesn't work contact us, with your order details please.</span>
        </div>
      );
    }

    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Congratulations 🎉
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Your reservation</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                {venueData?.venue.images[0] && <img
                  alt=""
                  className="absolute inset-0 object-cover"
                  sizes="200px"
                  src={venueData?.venue.images[0].image_url}
                />}
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {venueData?.venue.name}
                </span>
                <span className="text-base font-medium mt-1 block">
                  {venueData?.venue.city}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {venueData?.venue.provider.company_name}
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              {!!venueData?.venue.star_rating && <StartRating point={venueData?.venue.star_rating} />}
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-md font-semibold">
                  From : {orderData?.order.reservation_period.lower}
                </span>
                <span className="mt-1.5 text-md font-semibold">
                  To : {orderData?.order.reservation_period.upper}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">{orderData?.order.capacity} Guests</span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Reservation details</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">order id</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {orderData?.order.id}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment id</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {orderData?.order.payment_id}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Price</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {t('currency.sar')} {orderData?.order.price}
              </span>
            </div>
          </div>
        </div>
        <div>
          <ButtonPrimary href="/">Explore more stays</ButtonPrimary>
        </div>

      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
