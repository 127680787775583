import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";


export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "navigation.home",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/venues",
    name: "navigation.venues",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "navigation.aboutUs",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "navigation.contactUs",
  },
];
