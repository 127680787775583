import React, { FC, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";

export interface ImagePickerProps {
    className?: string;
    minNumber: number;
    maxNumber: number;
    onSubmit: (images: ImageListType) => void;
}

const ImagePicker: FC<ImagePickerProps> = ({ className = "", minNumber, maxNumber, onSubmit }) => {
    const [images, setImages] = useState<ImageListType>([]);
    const [lowNumOfImagesError, setLowNumOfImagesError] = useState(false);
    const maxFileSize = 5 * 1024 * 1024;

    const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
        if (imageList.length < minNumber) {
            setLowNumOfImagesError(true);
        } else {
            setLowNumOfImagesError(false);
        }
        setImages(imageList);
    };

    const handleSubmit = () => {
        onSubmit(images);
    };

    const hasErrors = lowNumOfImagesError || images.some(image => {
        const { file } = image;
        return !file || file.size > maxFileSize || !['image/png', 'image/jpeg'].includes(file.type);
    });

    return (
        <div className={className}>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                maxFileSize={maxFileSize}
                dataURLKey="data_url"
                acceptType={["png", "jpg", "jpeg"]}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors
                }) => (
                    <div className="upload__image-wrapper">
                        {errors && <div className="text-red-500">
                            {errors.maxNumber && <span>Cannot upload more than {maxNumber} images</span>}
                            {errors.acceptType && <span>Only jpg and png are allowed</span>}
                            {errors.maxFileSize && <span>Selected file size exceeds {maxFileSize / (1024 * 1024)} MB</span>}
                            {errors.resolution && <span>Selected file does not match your desired resolution</span>}
                        </div>}
                        {lowNumOfImagesError && <span className="text-red-500">You need to upload at least {minNumber} images</span>}
                        <div className={`mt-5 cursor-pointer ${isDragging ? 'bg-secondary-100' : ''}`}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            <div className="mt-1 mb-10 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                                <div className="space-y-1 text-center">
                                    <svg
                                        className="mx-auto h-12 w-12 text-neutral-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                                        <span className="relative rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">Upload a file</span>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                    <p className="text-xs text-neutral-500 dark:text-neutral-400">JPG, PNG up to 10MB</p>
                                </div>
                            </div>
                        </div>
                        <button onClick={onImageRemoveAll} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">Remove all images</button>
                        <div className="mt-4 grid grid-cols-3 gap-4">
                            {imageList.map((image, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    <img src={image.data_url} alt="" className="h-24 w-24 object-cover rounded" />
                                    <div className="mt-2">
                                        <button onClick={() => onImageUpdate(index)} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded me-1 mb-1">Update</button>
                                        <button onClick={() => onImageRemove(index)} className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded">Remove</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {!hasErrors && (
                            <button onClick={handleSubmit} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-4">Submit Images</button>
                        )}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
};

export default ImagePicker;
