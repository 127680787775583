import { MapPinIcon } from "@heroicons/react/24/solid";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import Label from "components/Label/Label";
import GoogleMapReact from "google-map-react";
import React, { FC, useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import dataEnglishJSON from 'i18n/en.json';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCoordinates, setCity, setStreet, setStreetArabic } from "state/addVenueSlice";
import { useGetCoordinatesQuery } from "state/api/googleMapsApiSlice";
import { RootState } from "state/store";

export interface PageAddListing2Props { }

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const { t } = useTranslation();
  const availableCities = Object.keys(dataEnglishJSON.cities);
  const availableCountries = Object.keys(dataEnglishJSON.countries);
  const dispatch = useDispatch();
  const street = useSelector((state: RootState) => state.addVenue.postVenueNoImages.street);
  const street_ar = useSelector((state: RootState) => state.addVenue.postVenueNoImages.street_ar);
  const city = useSelector((state: RootState) => state.addVenue.postVenueNoImages.city);
  const country = useSelector((state: RootState) => state.addVenue.postVenueNoImages.country);
  const [starterCoordinates, setStarterCoordinates] = useState({ latitude: -1, longitude: -1 });
  const { data } = useGetCoordinatesQuery(`${street_ar}${street},${city},${country}`);

  // Effect to set starterCoordinates when data from API is available
  useEffect(() => {
    if (data) {
      const returnedCoordinates = data.results[0].geometry.location;
      setStarterCoordinates({ latitude: returnedCoordinates.lat, longitude: returnedCoordinates.lng });
    }
  }, [data]);

  // Effect to handle beforeunload warning
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  // Handler for city change
  const onCityChange = (v: React.ChangeEvent<HTMLSelectElement>) => {
    const cityKey = v.target.value;
    dispatch(setCity({
      city: t(`cities.${cityKey}`, { lng: 'en' }),
      city_ar: t(`cities.${cityKey}`, { lng: 'ar' })
    }));
  };

  // Handlers for street name changes
  const onStreetEnglishChange = (v: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setStreet(v.target.value));
  };

  const onStreetArabicChange = (v: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setStreetArabic(v.target.value));
  };

  return (
    <CommonLayout
      index="02"
      nextHref="/add-listing-3"
      backtHref="/add-listing-1"
    >
      <>
        <h2 className="text-2xl font-semibold">Your place location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* Country selection */}
          <FormItem label="Country">
            <Select>
              {availableCountries.map((item) => (
                <option key={item} value={item}>{t(`countries.${item}`)}</option>
              ))}
            </Select>
          </FormItem>
          {/* City selection */}
          <FormItem label="City">
            <Select onChange={(v) => onCityChange(v)}>
              {availableCities.map((item) => (
                <option key={item} value={item}>{t(`cities.${item}`)}</option>
              ))}
            </Select>
          </FormItem>
          {/* Street name in English */}
          <FormItem label="Street name in english">
            <Input onChange={(v) => onStreetEnglishChange(v)} />
          </FormItem>
          {/* Street name in Arabic */}
          <FormItem label="Street name in arabic">
            <Input onChange={(v) => onStreetArabicChange(v)} />
          </FormItem>
          {/* Google Map component */}
          <div>
            <Label>Select the exact address</Label>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  <GoogleMapReact
                    onChange={({ center }) => {
                      dispatch(setCoordinates({ latitude: center.lat, longitude: center.lng }));
                    }}
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={15}
                    center={{ lat: starterCoordinates.latitude, lng: starterCoordinates.longitude }}
                  >
                    <LocationMarker lat={starterCoordinates.latitude} lng={starterCoordinates.longitude} />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </CommonLayout >
  );
};

export default PageAddListing2;
