import React, { FC, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from 'react-i18next';
import { useSignInMutation } from "state/api/apiSlice";
import { SignInRequest } from "types/auth";
import { validateEmailFormat } from "utils/validators";
import DefaultLoading from "shared/Loading/DefaultLoading";
import { useDispatch, useSelector } from "react-redux";
import { signInUser } from "state/userSlice";
export interface PageLoginProps {
  className?: string;
}


const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const router = useNavigate();
  //redux
  const dispatch = useDispatch();

  //rtk query
  const [signIn, { isLoading, error }] = useSignInMutation();

  //form inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //validation
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateEmail = () => {
    if (email === '') {
      setEmailError(t('auth.errors.emailIsRequired'));
      return false;
    } if (!validateEmailFormat(email)) {
      setEmailError(t('auth.errors.emailFormatInvalid'));
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePassword = () => {
    if (password === '') {
      setPasswordError(t('auth.errors.passwordIsRequired'));
      return false;
    }
    setPasswordError("");
    return true;
  };

  const handleContinueClick = async () => {
    console.log('handleContinueClick called');
  
    if (validateEmail()) {
      console.log('Email validation passed');
    } else {
      console.log('Email validation failed');
    }
  
    if (validatePassword()) {
      console.log('Password validation passed');
    } else {
      console.log('Password validation failed');
    }
  
    if (validateEmail() && validatePassword()) {
      const credentials: SignInRequest = {
        email,
        password
      };
      console.log('Credentials:', credentials);
  
      const response = await signIn(credentials);
      console.log('Sign-in response:', response);
  
      if ('data' in response) {
        console.log('Sign-in response contains data');
        dispatch(signInUser(response.data!));
        console.log('User signed in and dispatched to store');
  
        router('/');
        console.log('Navigated to home');
      } else {
        console.log('Sign-in response does not contain data');
      }
    } else {
      console.log('Email or password validation failed');
    }
  };
  

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <HelmetProvider>
        <Helmet>
          <title>{t('auth.login')}</title>
        </Helmet>
      </HelmetProvider>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('auth.login')}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('auth.emailAddress')}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <p className="text-red-500">{emailError}</p>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('auth.password')}
                <Link to="/request-reset-password" className="text-sm text-primary-6000">
                  {t('auth.forgotPassword')}
                </Link>
              </span>
              <Input
                type="password"
                className="mt-1"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && <p className="text-red-500">{passwordError}</p>}
              {error && <p className="text-red-500">{t('auth.errors.wrongEmailOrPassword')}</p>}
            </label>
            <ButtonPrimary type="button" onClick={handleContinueClick}>
              {isLoading ? <DefaultLoading /> : t('auth.continue')}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('auth.newUser')} {` `}
            <Link to="/signup" className="text-primary-6000">{t('auth.createAnAccount')}</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
