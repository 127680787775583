import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Venue } from 'types/venue';


export interface selectedVenue {
    selectedVenue: Venue | null;
}

const initialState: selectedVenue = {
    selectedVenue: null
}

export const selectedVenueSlice = createSlice({
    name: 'selectedVenue',
    initialState,
    reducers: {
        setSelectedVenue: (state, action: PayloadAction<Venue>) => {
            state.selectedVenue = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSelectedVenue } = selectedVenueSlice.actions

export default selectedVenueSlice.reducer