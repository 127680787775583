import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFeature, updateFeature, deleteFeature } from 'state/addVenueSlice';
import { RootState } from 'state/store';
import CommonLayout from './CommonLayout';
import { Feature } from 'types/venue';

const ManageFeatures = () => {
  const dispatch = useDispatch();
  const features = useSelector((state: RootState) => state.addVenue.postVenueNoImages.features);
  const [currentFeature, setCurrentFeature] = useState({ name: '', name_ar: '' });
  const [errors, setErrors] = useState({ name: '', name_ar: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editingFeatureId, setEditingFeatureId] = useState<number | null>(null);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setCurrentFeature({ ...currentFeature, [name]: value });
  };

  const validateInput = () => {
    const maxLength = 100;
    const newErrors = { name: '', name_ar: '' };
    if (!currentFeature.name) {
      newErrors.name = 'English feature name is required.';
    } else if (currentFeature.name.length > maxLength) {
      newErrors.name = `English feature name cannot exceed ${maxLength} characters.`;
    }
    if (!currentFeature.name_ar) {
      newErrors.name_ar = 'Arabic feature name is required.';
    } else if (currentFeature.name_ar.length > maxLength) {
      newErrors.name_ar = `Arabic feature name cannot exceed ${maxLength} characters.`;
    }
    setErrors(newErrors);
    return !newErrors.name && !newErrors.name_ar;
  };

  const handleAddFeature = () => {
    if (validateInput()) {
      dispatch(addFeature(currentFeature));
      setCurrentFeature({ name: '', name_ar: '' });
      setErrors({ name: '', name_ar: '' });
    }
  };

  const handleUpdateFeature = () => {
    if (editingFeatureId !== null && validateInput()) {
      dispatch(updateFeature({ id: editingFeatureId, ...currentFeature }));
      setCurrentFeature({ name: '', name_ar: '' });
      setIsEditing(false);
      setEditingFeatureId(null);
      setErrors({ name: '', name_ar: '' });
    }
  };

  const handleEditFeature = (feature: Feature) => {
    setCurrentFeature({ name: feature.name, name_ar: feature.name_ar });
    setIsEditing(true);
    setEditingFeatureId(feature.id);
  };

  const handleDeleteFeature = (id: number) => {
    dispatch(deleteFeature(id));
  };

  return (
    <CommonLayout index="05" backtHref="/add-listing-4" nextHref="/add-listing-6">
      <div className="max-w-2xl mx-auto p-4 bg-white rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4">Manage Features</h3>
        <p className="text-sm text-gray-500 mb-4">Example: State-of-the-art sound system and lighting to enhance the ambiance of the event.</p>
        <form className="mb-6">
          <div className="mb-4">
            <label className="block text-gray-700">Feature in English</label>
            <input
              type="text"
              name="name"
              value={currentFeature.name}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200"
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Feature in Arabic</label>
            <input
              type="text"
              name="name_ar"
              value={currentFeature.name_ar}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200"
            />
            {errors.name_ar && <p className="text-red-500 text-sm mt-1">{errors.name_ar}</p>}
          </div>
          <button
            type="button"
            onClick={isEditing ? handleUpdateFeature : handleAddFeature}
            className="px-4 py-2 bg-primary-700 text-white rounded-md shadow-sm hover:bg-primary-800 focus:outline-none focus:ring focus:ring-primary-200"
          >
            {isEditing ? 'Update Feature' : 'Add Feature'}
          </button>
        </form>
        <ul className="space-y-2">
          {features.map((feature) => (
            <li key={feature.id} className="flex flex-col sm:flex-row justify-between items-start sm:items-center p-2 border border-gray-200 rounded-md shadow-sm">
              <div className="flex flex-col">
                <p className="block w-full sm:w-auto break-words">English: {feature.name}</p>
                <p className="block w-full sm:w-auto break-words">Arabic: {feature.name_ar}</p>
              </div>
              <div className="flex space-x-2 rtl:space-x-reverse mt-2 sm:mt-0">
                <button
                  onClick={() => handleEditFeature(feature)}
                  className="px-3 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-yellow-200"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteFeature(feature.id)}
                  className="px-3 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-200"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </CommonLayout>
  );
};

export default ManageFeatures;
