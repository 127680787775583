import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import { useTranslation } from "react-i18next";
import data from 'i18n/en.json';
import { useDispatch, useSelector } from "react-redux";
import { setSearchLocation } from "state/venueSearchSlice";
import { RootState } from "state/store";



interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
}

const LocationInput: FC<Props> = ({

  onChange = () => { },
  className = "",
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useSelector((state: RootState) => state.venueSearch.location)
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const handleSelectLocation = (item: string) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      const cityNameEnglish = data.cities[item as keyof typeof data.cities];
      dispatch(setSearchLocation(cityNameEnglish));
      onChange && onChange(item);
    }, 0);
  };
  const availableCities = Object.keys(data.cities);

  const renderAllAvailableCities = () => {
    return (
      <>
        <p className="block font-semibold text-base">
          {t('filterVenues.availableCities')}
        </p>
        <div className="mt-3">
          {availableCities.map((item) => {
            const cityName = t(`cities.${item}`)
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                onClick={() => handleSelectLocation(item)}
                key={item}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{cityName}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };
  const renderSearchValues = (value: string) => {
    const filteredCities = availableCities.filter(city => {
      const cityName = t(`cities.${city}`).toLowerCase();
      return cityName.includes(value.toLowerCase());
    });
    return (
      <>
        <h3 className="block font-semibold text-base">
          {t('filterVenues.searchValues')}
        </h3>
        <div className="mt-2">
          {filteredCities.map((item) => {
            const cityName = t(`cities.${item}`)
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                onClick={() => handleSelectLocation(item)}
                key={item}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{cityName}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };


  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {t('filterVenues.venueLocation')}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={t('filterVenues.searchDestinations')}
            value={location}
            onChange={(e) => dispatch(setSearchLocation(e.currentTarget.value))}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {location
            ? renderSearchValues(location)
            : renderAllAvailableCities()
          }
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
