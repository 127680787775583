import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";

import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";

import { Venue } from "types/venue";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVenue } from "state/selectedVenueSlice";
import { useTranslation } from "react-i18next";
import { RootState } from "state/store";

export interface StayCardProps {
  className?: string;
  data: Venue;
  size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state: RootState) => state.language);
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${data.id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={data.images}
          href={`/venue/${data.id}`}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {t(`venueTypes.${data.type}`)} · {data.capacity} {t('filterVenues.guests')}
          </span>
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >
              <span className="line-clamp-1">
                {currentLanguage === 'en' ? data.name : data.name_ar}
              </span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{currentLanguage === 'en' ? data.city : data.city_ar}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {data.budget}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                {t('currency.sar')}/{data.type === 'wedding_hall' ? t('venueDetails.day') : t('venueDetails.hour')}
              </span>
            )}
          </span>
          {!!data.star_rating && (
            <StartRating point={data.star_rating} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
      onClick={() => dispatch(setSelectedVenue(data))}
    >
      {renderSliderGallery()}
      <Link to={`/venue/${data.id}`}>{renderContent()}</Link>
    </div>
  );
};

export default StayCard;
