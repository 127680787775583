import { Popover, Transition } from "@headlessui/react";
import {
  ShoppingBagIcon,
  UserCircleIcon,
  HomeIcon,
  ArrowLeftStartOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { CONSTANTS } from "data/constants";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLogoutMutation } from "state/api/apiSlice";
import { RootState } from "state/store";
import { signOutUser } from "state/userSlice";

interface LinkWithIconProps {
  href: string;
  icon: any;
  name: string;
  onClick?: () => void;
}

const LinkWithIcon: React.FC<LinkWithIconProps> = ({ href, icon: Icon, name, onClick }) => {
  return (
    <Link
      to={href}
      onClick={onClick}
      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
    >
      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
        <Icon aria-hidden="true" className="w-6 h-6" />
      </div>
      <div className="ms-4">
        <p className="text-sm font-medium">{name}</p>
      </div>
    </Link>
  );
};

export default function AvatarDropdown() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = useSelector((state: RootState) => state.user.details?.type);

  const [logout, { isLoading, isSuccess, isError }] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      dispatch(signOutUser());
      navigate('/login'); // Redirect to login page after logout
    } catch (err) {
      // Handle error
      console.error("Logout failed", err);
    }
  };

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className="inline-flex items-center justify-center w-10 h-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <UserCircleIcon className="w-8 h-8 sm:w-9 sm:h-9 text-white dark:text-neutral-300" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -end-10 sm:end-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    <LinkWithIcon
                      href="/account"
                      icon={UserCircleIcon}
                      name={t('navigation.account')}
                    />
                    {userType === CONSTANTS.userType.provider &&
                      <LinkWithIcon
                        href="/add-listing-1"
                        icon={HomeIcon}
                        name={t('navigation.addAVenue')}
                      />
                    }

                    <LinkWithIcon
                      href="/orders"
                      icon={ShoppingBagIcon}
                      name={t('navigation.orders')}
                    />

                  </div>
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    <LinkWithIcon
                      href="/"
                      icon={ArrowLeftStartOnRectangleIcon}
                      name={t('auth.logout')}
                      onClick={handleLogout}
                    />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
