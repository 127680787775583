
import { FC, Fragment, useState } from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { CONSTANTS } from "data/constants";
import { useTranslation } from "react-i18next";
import CustomerOrders from "./CustomerOrders";
import ProviderVenues from "./ProviderVenues";

export interface OrdersPageProps {
  className?: string;
}

const OrdersPage: FC<OrdersPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user.details);

  return (
    <div className={`nc-OrdersPage ${className}`} data-nc-id="OrdersPage">
      <HelmetProvider>
        <Helmet>
          <title>{t('navigation.orders')}</title>
        </Helmet>
      </HelmetProvider>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-col">
        <h1 className="text-2xl font-bold mb-4">{'Reservations'}</h1>
        {user?.type === CONSTANTS.userType.customer && <CustomerOrders />}
        {user?.type === CONSTANTS.userType.provider && <ProviderVenues providerId={user.id} />}
      </main>
    </div>
  );
};

export default OrdersPage;
