import { apiSlice } from './api/apiSlice'
import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import venueSearchReducer from './venueSearchSlice'
import addVenueReducer from './addVenueSlice'
import selectedVenueReducer from './selectedVenueSlice'
import orderReducer from './orderSlice'
import languageReducer from './languageSlice'
import { googleMapsApiSlice } from './api/googleMapsApiSlice'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        [googleMapsApiSlice.reducerPath]: googleMapsApiSlice.reducer,
        user: userReducer,
        venueSearch: venueSearchReducer,
        addVenue: addVenueReducer,
        selectedVenue: selectedVenueReducer,
        order: orderReducer,
        language: languageReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware).concat(googleMapsApiSlice.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch