import { FC, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from 'react-i18next';
import { useRequestResetPasswordMutation } from "state/api/apiSlice";
import { RequestResetPasswordRequest, SignInRequest } from "types/auth";
import { emailRegex } from "utils/validators";
import DefaultLoading from "shared/Loading/DefaultLoading";
import CustomInput from "shared/CustomInput/CustomInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { CONSTANTS } from "data/constants";

export interface PageResetPasswordProps {
    className?: string;
}

interface FormData {
    email: string;
}


const PageRequestResetPassword: FC<PageResetPasswordProps> = ({ className = "" }) => {
    const { t } = useTranslation();
    const [passwordResetRequestSuccess, setPasswordResetRequestSuccess] = useState(false);


    //rtk query
    const [requestResetPassword, { isLoading, error }] = useRequestResetPasswordMutation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>()

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
        const requestData: RequestResetPasswordRequest = {
            email: formData.email,
            redirect_url: `${CONSTANTS.frontendBaseURL}/reset-password`
        }
        const response = await requestResetPassword(requestData);
        if ('data' in response) {
            console.log(response)
            setPasswordResetRequestSuccess(true);
        }
    }

    return (
        <div className={`nc-PageRequestResetPassword ${className}`} data-nc-id="PageRequestResetPassword">
            <HelmetProvider>
                <Helmet>
                    <title>{t('auth.resetPassword')}</title>
                </Helmet>
            </HelmetProvider>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    {t('auth.resetPassword')}
                </h2>
                <div className="max-w-md mx-auto space-y-6 ">
                    <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                        <CustomInput label={t('auth.emailAddress')} register={register('email', {
                            required: t('auth.errors.thisFieldIsRequired'),
                            pattern: {
                                value: emailRegex,
                                message: t('auth.errors.emailFormatInvalid')
                            }
                        })} />
                        {errors.email && <p className="text-red-500">{errors.email?.message}</p>}

                        {passwordResetRequestSuccess ?
                            <p className="text-primary-800 text-center">
                                {t('auth.errors.resetPasswordIntructionsSentToYourEmail')}
                            </p>
                            :
                            <ButtonPrimary type="submit">
                                {isLoading ? <DefaultLoading /> : t('auth.continue')}
                            </ButtonPrimary>
                        }
                        {error && <p className="text-red-500 text-center">{t('auth.errors.thereIsNoUserWithThisEmail')}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PageRequestResetPassword;
