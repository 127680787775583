import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon, CreditCardIcon, TruckIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useGetCustomerOrdersQuery } from "state/api/apiSlice";
import DefaultLoading from "shared/Loading/DefaultLoading";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CustomerOrdersProps {
    className?: string;
}

const CustomerOrders: FC<CustomerOrdersProps> = ({ className = "" }) => {
    const { data, error, isLoading, refetch } = useGetCustomerOrdersQuery();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        refetch()
    }, [])

    const handleOrderClick = (orderId: number) => {
        console.log(`Order ${orderId} clicked`);
        navigate(`/order-status/${orderId}`);
    };

    const renderIcon = (status: string) => {
        switch (status) {
            case 'Pending Approval':
                return <ExclamationCircleIcon className="h-6 w-6 text-orange-500" />;
            case 'Approved - Pending Payment':
                return <CreditCardIcon className="h-6 w-6 text-yellow-500" />;
            case 'Payment Expired':
            case 'Declined':
                return <XCircleIcon className="h-6 w-6 text-red-500" />;
            case 'Confirmed':
                return <CheckCircleIcon className="h-6 w-6 text-green-500" />;
            default:
                return <TruckIcon className="h-6 w-6 text-gray-500" />;
        }
    };

    return (
        <div className={`${className} p-4`}>
            
            {isLoading && <DefaultLoading />}
            {!isLoading && !error && data && data.orders.length === 0 && (
                <div className="flex flex-col items-center justify-center bg-gray-100 p-6 border border-gray-300 rounded-lg text-center">
                    <TruckIcon className="h-12 w-12 text-gray-500 mb-4" />
                    <div className="text-lg text-gray-700 font-semibold">{'You haven\'t made any reservations yet.'}</div>
                    <ButtonSecondary onClick={() => navigate('/venues')} className="mt-4">
                        {'Browse Venues'}
                    </ButtonSecondary>
                </div>
            )}
            {!isLoading && !error && data && data.orders.length > 0 && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {data.orders.map(order => (
                        <div
                            key={order.id}
                            className="border rounded-lg p-4 flex flex-col cursor-pointer hover:bg-gray-100"
                            onClick={() => handleOrderClick(order.id)}
                        >
                            <div className="flex items-center mb-2">
                                {renderIcon(order.status)}
                                <span className="ml-2 text-lg font-semibold">{t(order.status)}</span>
                            </div>
                            <div className="text-sm text-gray-500">{new Date(order.created_at).toLocaleString()}</div>
                            <div className="mt-2 text-sm">
                                <div>{t('Capacity')}: {order.capacity}</div>
                                <div>{t('Price')}: {order.price} {t('currency.sar')}</div>
                                <div>{t('Reservation Period')}: {new Date(order.reservation_period.lower).toLocaleString()} - {new Date(order.reservation_period.upper).toLocaleString()}</div>
                                <div>{t('Payment Status')}: {order.payment_status}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomerOrders;
