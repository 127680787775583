import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { CONSTANTS } from 'data/constants';
import { Order } from 'types/order';


interface MoyasarPaymentProps {
    order: Order;
}
const MoyasarPayment: React.FC<MoyasarPaymentProps> = ({ order }) => {
    const callBackURL = `${CONSTANTS.frontendBaseURL}/pay-done/${order.id}`;


    useEffect(() => {
        // Add Moyasar Styles
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdn.moyasar.com/mpf/1.13.0/moyasar.css';
        document.head.appendChild(link);

        // Ensure the Moyasar script is loaded before calling Moyasar.init
        const script = document.createElement('script');
        script.src = 'https://cdn.moyasar.com/mpf/1.13.0/moyasar.js';
        script.onload = () => {
            // Initialize Moyasar after the script is loaded
            if (order && typeof order.price === 'number') {
                (window as any).Moyasar.init({
                    element: '.mysr-form',
                    amount: 100 * order.price,
                    currency: 'SAR',
                    description: `Order id in the database : ${order.id}`,
                    publishable_api_key: process.env.REACT_APP_MOYASAR_PUBLISHABLE_API_KEY,
                    callback_url: callBackURL,
                    methods: ['creditcard'],
                    on_completed: function (payment: any) {
                        return new Promise(async function (resolve, reject) {
                            try {
                                // First API request
                                await fetch(`${CONSTANTS.backendBaseURL}orders/${order.id}/payment_id`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    credentials: 'include', // Include credentials in the request
                                    body: JSON.stringify({ payment_id: payment.id })
                                });

                                resolve({});
                            } catch (error) {
                                reject(error);
                            }
                        });
                    },
                });
            }
        };
        document.body.appendChild(script);

        // Clean up function to remove added scripts on component unmount
        return () => {
            document.head.removeChild(link);
            document.body.removeChild(script);
        };
    }, [order]); // Dependency array to re-run effect when newOrder changes

    return (
        <section>
            <div className="mysr-form" style={{ width: '360px', margin: '40px 0 0 0' }}></div>
        </section>
    );
};

export default MoyasarPayment;
