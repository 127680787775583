import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CustomerRegisterForm from "./CustomerRegisterForm";
import ProviderRegisterForm from "./ProviderRegisterForm";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [isServiceProvider, setIsServiceProvider] = useState(false);

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <HelmetProvider>
        <Helmet>
          <title>{t('auth.signup')}</title>
        </Helmet>
      </HelmetProvider>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('auth.signup')}
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* Checkbox for service provider */}
          <label className="block">
            <input
              type="checkbox"
              checked={isServiceProvider}
              onChange={(e) => setIsServiceProvider(e.target.checked)}
            />
            {' '}
            {t('auth.iAmAServiceProvider')}
          </label>

          {/* Conditionally render form based on checkbox value */}
          {isServiceProvider ? <ProviderRegisterForm /> : <CustomerRegisterForm />}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('auth.alreadyHaveAnAccount')} {` `}
            <Link to="/login" className="text-primary-6000">{t('auth.login')}</Link>
          </span>
          {/* Terms and Conditions agreement */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300 text-sm mt-4">
            {t('auth.bySigningUp')} <Link to="/terms-conditions" className="text-primary-6000">{t('footer.termsAndConditions')}</Link>.
          </span>

        </div>
      </div>
    </div >
  );
};

export default PageSignUp;
