import React, { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { setOccasions } from "state/addVenueSlice";
import VenueOptionsCheckList from "./VenueOptionsChecklist";
import VenueOccasionsCheckList from "./VenueOccasionsChecklist";

export interface PageAddListing7Props { }

const PageAddListing7: FC<PageAddListing7Props> = () => {
  //warns data will be lost before reload or exit
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const dispatch = useDispatch();
  const selectedOccasions = useSelector(
    (state: RootState) => state.addVenue.postVenueNoImages.occasions
  );

  const handleCheckboxChange = (itemId: number) => {
    const updatedOccasions = selectedOccasions.includes(itemId)
      ? selectedOccasions.filter((id) => id !== itemId)
      : [...selectedOccasions, itemId];
    console.log(updatedOccasions);

    dispatch(setOccasions(updatedOccasions));
  };

  const handleImageClick = (itemId: number) => {
    // Simulate checkbox toggle
    const checkbox = document.getElementById(`venue-occasion-${itemId}`) as HTMLInputElement;
    checkbox.checked = !checkbox.checked;

    // Call the checkbox change handler
    handleCheckboxChange(itemId);
  };

  return (
    <CommonLayout index="07" backtHref="/add-listing-6" nextHref="/add-listing-8">
      <div>
        <VenueOccasionsCheckList className="mb-5" />
        <VenueOptionsCheckList />
      </div>
    </CommonLayout>
  );
};

export default PageAddListing7;
