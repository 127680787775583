import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2Mobile from "components/HeroSearchForm2Mobile/HeroSearchForm2Mobile";
import AuthenticationButtons from "./AuthenticationButtons";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import AddVenueButton from "./AddVenueButton";
import LangDropdown from "components/Header/LangDropdown"; // Import the LangDropdown component
import logoLightImg from "images/logo-light.svg";
import AvatarDropdown from "./AvatarDropdown";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const userSignedIn = useSelector((state: RootState) => state.user.signedIn);
  const userType = useSelector((state: RootState) => state.user.details?.type);

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 rtl:space-x-reverse sm:space-x-10">
          <Logo img={logoLightImg} />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2Mobile />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            {/* <SwitchDarkMode /> */}
            <LangDropdown buttonClassName="text-white" />
            {!userSignedIn && <AuthenticationButtons />}
            {userSignedIn && <AvatarDropdown />}
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex xl:hidden items-center">
              {/* <SwitchDarkMode /> */}
              <div className="px-0.5" />
              <MenuBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
