import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "state/store";
import { orderStatusTranslations, translateOrderStatus } from "utils/translators";

export interface ProviderOrderCardProps {
    data: {
        id: number;
        capacity: number;
        created_at: string;
        price: number;
        reservation_period: {
            lower: string;
            upper: string;
        };
        status: string;
        payment_status: string;
    };
}

const ProviderOrderCard: FC<ProviderOrderCardProps> = ({ data }) => {
    const { t } = useTranslation();
    const { currentLanguage } = useSelector((state: RootState) => state.language);


    return (
        <div className="p-4 bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl shadow-sm">
            <div className="space-y-2">
                <h3 className="text-lg font-medium">{t('order.id')}: {data.id}</h3>
                <p>{t('order.numberOfGuests')}: {data.capacity}</p>
                <p>{t('order.createdAt')}: {new Date(data.created_at).toLocaleString()}</p>
                <p>{t('order.price')}: {data.price} {t('currency.sar')}</p>
                <p>{t('order.reservationPeriodFrom')}: {new Date(data.reservation_period.lower).toLocaleString()}</p>
                <p>{t('order.reservationPeriodTo')}:  {new Date(data.reservation_period.upper).toLocaleString()}</p>

                <p>{t('order.status')}: {currentLanguage === 'en' ? data.status : translateOrderStatus(data.status as keyof typeof orderStatusTranslations)}</p>
                <p>{t('order.paymentStatus')}: {data.payment_status}</p>
            </div>
            <Link to={`/order-status/${data.id}`} className="block mt-4 text-blue-600 hover:underline">
                {t('order.viewDetails')}
            </Link>
        </div>
    );
};

export default ProviderOrderCard;
