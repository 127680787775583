import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { CONSTANTS } from 'data/constants';
import { OrderStatus } from 'types/order';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useNavigate } from 'react-router-dom';

interface StatusSectionProps {
    status: OrderStatus;
    orderId: number;
}

const StatusSection: FC<StatusSectionProps> = ({ status, orderId }) => {
    const navigate = useNavigate();
    const getStatusColorClasses = (status: OrderStatus): string => {
        switch (status) {
            case 'Pending Approval':
                return 'text-orange-500';
            case 'Approved - Pending Payment':
                return 'text-blue-500';
            case 'Payment Expired':
                return 'text-red-500';
            case 'Declined':
                return 'text-gray-500';
            case 'Confirmed':
                return 'text-green-500';
            default:
                return 'text-black';
        }
    };

    const getStatusExplanation = (status: OrderStatus): string => {
        switch (status) {
            case 'Pending Approval':
                return 'Your reservation is pending approval. We will send you an email once it is approved.';
            case 'Approved - Pending Payment':
                return 'Your reservation is approved but pending payment. Please proceed with the payment to confirm your order.';
            case 'Payment Expired':
                return 'The payment period for your reservation has expired. Please re-reserve and check your email regularly.';
            case 'Declined':
                return 'Your reservation has been declined by the venue owner.';
            case 'Confirmed':
                return 'Your reservation is confirmed. Thank you for your purchase!';
            default:
                return '';
        }
    };

    const statusColorClasses = getStatusColorClasses(status);
    const statusExplanation = getStatusExplanation(status);

    const handlePaymentClick = () => {
        navigate(`/checkout/${orderId}`);
    };

    return (
        <section className="p-4">
            <h1 className={`text-2xl font-bold ${statusColorClasses}`}>
                Your reservation is: {status}
            </h1>
            <p className="mt-2 text-md">{statusExplanation}</p>
            {status === 'Approved - Pending Payment' && (
                <ButtonPrimary
                    className='mt-2'
                    onClick={handlePaymentClick}>
                    Pay Now
                </ButtonPrimary>
            )}
        </section>
    );
};

export default StatusSection;
