import React, { FC, useEffect, useState } from "react";
import GuestsInput from "./GuestsInput";
import StartRating from "components/StartRating/StartRating";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { setNewOrder, setNewOrderCapacity, setNewOrderFromDate, setNewOrderToDate, setNewOrderVenueId, setOrderVenue } from "state/orderSlice";
import { Venue } from "types/venue";
import { useTranslation } from "react-i18next";
import HourlyDatePicker from "./HourlyDatePicker";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useCreateOrderMutation } from "state/api/apiSlice";
import { useNavigate } from "react-router-dom";
import DefaultLoading from "shared/Loading/DefaultLoading";
import {convertSelectedDateToString} from "utils/convertSelectedDateToString";

interface HourlySideBarProps {
    venue: Venue;
}

const HourlySideBar: FC<HourlySideBarProps> = ({ venue }) => {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedTime, setSelectedTime] = useState<Date | null>(null);
    const newOrderParameters = useSelector((state: RootState) => state.order.newOrderParameters);
    const [createOrder, { isLoading, error }] = useCreateOrderMutation();
    const capacity = useSelector((state: RootState) => state.order.newOrderParameters.capacity);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(setNewOrderVenueId(venue.id));
        if (!capacity) {
            dispatch(setNewOrderCapacity(1));
        }
    }, [venue.id, capacity, dispatch]);

    const handleCapacityChange = (value: number) => {
        dispatch(setNewOrderCapacity(value));
    };


    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        setSelectedTime(date);
    
    };

    const handleSelectedTimeChange = (time: Date | null) => {
        setSelectedTime(time);
        if(time !== null){
            const toTime : Date = new Date(time)
            toTime.setTime(toTime.getTime() + 1*59*60*1000)
            console.log('from', time)
            console.log('to', toTime)

            dispatch(setNewOrderFromDate(convertSelectedDateToString(time)));
            dispatch(setNewOrderToDate(convertSelectedDateToString(toTime)));

        }
    };


    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + venue.reservation_max_days_ahead);
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
   
    
    const onReserveClick = async () => {
        dispatch(setNewOrderVenueId(venue.id));
        if (selectedDate && selectedTime && capacity && newOrderParameters.venue_id) {
            const response = await createOrder(newOrderParameters);

            if ('data' in response) {
                dispatch(setNewOrder(response.data!.order));
                dispatch(setOrderVenue(response.data!.venue));
                navigate(`/order-status/${response.data!.order.id}`);
            }
        }
    };

    
    return (
        <div className="listingSectionSidebar__wrap shadow-xl">
            {/* PRICE */}
            <div className="flex justify-between">
                <span className="text-3xl font-semibold">
                    {venue.budget} {t('currency.sar')}
                    <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                        /{t('venueDetails.hour')}
                    </span>
                </span>
                {!!venue.star_rating && <StartRating point={venue.star_rating} />}
            </div>

            {/* FORM */}
            <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl">
                <HourlyDatePicker
                    selectedDate={selectedDate}
                    onDateChange={handleDateChange}
                    selectedTime={selectedTime}
                    onSelectedTimeChange={handleSelectedTimeChange}
                    earliest_check_in_hour={venue.earliest_check_in_hour}
                    latest_check_out_hour={venue.latest_check_out_hour}
                    minDate={minDate}
                    maxDate={maxDate}
                    unavailableDates={venue.unavailable_dates}
                />
                <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                <GuestsInput
                    capacity={capacity ?? 1}
                    maxCapacity={venue.capacity}
                    setCapacity={handleCapacityChange}
                    className="flex-1"
                />
            </form>
            {/* SUM */}
            {
                selectedDate && selectedTime && <div className="flex flex-col space-y-4">
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <span>Total</span>
                        <span>{t('currency.sar')} {( venue.budget).toFixed(2)}</span>
                    </div>
                </div>
            }
            {/* SUBMIT */}
            <ButtonPrimary disabled={!selectedDate || !selectedTime} onClick={onReserveClick}>
                {isLoading ? <DefaultLoading /> : t('order.reserve')}
            </ButtonPrimary>
            {(!selectedDate || !selectedDate) && <p className="text-primary-800 text-sm">{t('order.makeSureCheckInDateAndACheckoutDatesAreSelected')}</p>}
            {error && <p className="text-red-500 text-sm">{t('order.pleaseLoginToReserve')}</p>}

        </div>
    );
};

export default HourlySideBar;
