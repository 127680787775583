import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetProviderVenuesQuery } from "state/api/apiSlice";
import DefaultLoading from "shared/Loading/DefaultLoading";
import SimpleVenueCard from "./SimpleVenueCard";
export interface ProviderVenuesProps {
    className?: string;
    providerId: number;
}

const ProviderVenues: FC<ProviderVenuesProps> = ({ className = "", providerId }) => {
    const { data, error, isLoading } = useGetProviderVenuesQuery({ provider_id: providerId });
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (isLoading) {
        return <DefaultLoading />;
    }

    if (error) {
        return <div>{t("error.loadingVenues")}</div>;
    }

    return (
        <div className={`${className} grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6`}>
            {data && data.venues.map((venue) => (
                <SimpleVenueCard
                    key={venue.id}
                    data={venue}
                    size="default"
                />
            ))}
        </div>
    );
};

export default ProviderVenues;
