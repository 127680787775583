import React, { FC, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { useChangePasswordMutation, useInquiriesMutation } from "state/api/apiSlice";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { InquiriesRequest } from "types/contactUs";
import { emailRegex } from "utils/validators";
import DefaultLoading from "shared/Loading/DefaultLoading";

export interface PageContactProps {
  className?: string;
}


const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [changePassword] = useChangePasswordMutation();


  
const info = [
  {
    title: `💌 ${t('auth.emailAddress')}`,
    desc: "nc.example@example.com",
  },
  {
    title: `☎ ${t('auth.phoneNumber')}`,
    desc: "000-123-456-7890",
  },
];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InquiriesRequest>();
  const [inquirySuccess, setInquirySuccess] = useState("");
  const [sendInuiry, { isLoading, error }] = useInquiriesMutation();

  const onSubmit: SubmitHandler<InquiriesRequest> = async (data) => {
    const response = await sendInuiry(data);

    if ('data' in response) {
      setInquirySuccess(t("contactUsPage.messageSentWeWillContactYou"))
    }
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <HelmetProvider>
        <Helmet>
          <title>{t('navigation.contactUs')}</title>
        </Helmet>
      </HelmetProvider>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('navigation.contactUs')}
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 md:grid grid-cols-1 sm:grid-cols-[1fr,3fr] gap-12">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
            </div>
            <div>
              <form className="grid sm:grid-cols-2 grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                <label className="block">
                  <Label>{t('contactUsPage.fullName')}</Label>

                  <Input
                    type="text"
                    className="mt-1"
                    {...register('name', { required: t('auth.errors.thisFieldIsRequired') })}
                  />
                  {errors.name && <p className="text-red-500">{errors.name?.message}</p>}
                </label>
                <label className="block">
                  <Label>{t('auth.emailAddress')}</Label>

                  <Input
                    type="email"
                    className="mt-1"
                    {...register('email', {
                      required: t('auth.errors.thisFieldIsRequired'),
                      pattern: {
                        value: emailRegex,
                        message: t('auth.errors.emailFormatInvalid')
                      }
                    })}
                  />
                  {errors.email && <p className="text-red-500">{errors.email?.message}</p>}
                </label>
                <label className="block">
                  <Label>{t('auth.phoneNumber')}</Label>

                  <Input
                    type="text"
                    className="mt-1"
                    {...register('phone_number', { required: t('auth.errors.thisFieldIsRequired') })}
                  />
                  {errors.phone_number && <p className="text-red-500">{errors.phone_number?.message}</p>}

                </label>
                <label className="block">
                  <Label>{t('contactUsPage.messageSubject')}</Label>

                  <Input
                    type="text"
                    className="mt-1"
                    {...register('subject', { required: t('auth.errors.thisFieldIsRequired') })}
                  />
                  {errors.subject && <p className="text-red-500">{errors.subject?.message}</p>}

                </label>
                <label className="block sm:col-span-2 col-span-1 ">
                  <Label>{t('contactUsPage.message')}</Label>

                  <Textarea className="mt-1" rows={6} {...register('message', { required: t('auth.errors.thisFieldIsRequired') })} />
                  {errors.message && <p className="text-red-500">{errors.message?.message}</p>}

                </label>
                <div>
                  <ButtonPrimary >{isLoading ? <DefaultLoading /> : t('contactUsPage.sendMessage')}</ButtonPrimary>
                  <p className="text-primary-500 my-2">{inquirySuccess}</p>
                  {error && <p className="text-red-500">{t('contactUsPage.thereWasAnErrorPleaseTryAgain')}</p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default PageContact;
