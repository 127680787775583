import React, { FC, useEffect } from "react";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { setDescription, setDescriptionArabic } from "state/addVenueSlice";
import { RootState } from "state/store";

export interface PageAddListing4Props {}

const PageAddListing4: FC<PageAddListing4Props> = () => {
  // Warns data will be lost before reload or exit
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  
  const dispatch = useDispatch();
  const description = useSelector((state: RootState) => state.addVenue.postVenueNoImages.description);
  const descriptionArabic = useSelector((state: RootState) => state.addVenue.postVenueNoImages.description_ar);

  return (
    <CommonLayout
      index="04"
      backtHref="/add-listing-3"
      nextHref="/add-listing-5"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">
            Your venue's description in English
          </h2>
        </div>

        <Textarea
          onChange={(v) => dispatch(setDescription(v.target.value))}
          value={description} // Set the value from the state
          placeholder=""
          rows={10}
        />

        <h2 className="text-2xl font-semibold">
          Your venue's description in Arabic
        </h2>

        <Textarea
          onChange={(v) => dispatch(setDescriptionArabic(v.target.value))}
          value={descriptionArabic} // Set the value from the state
          placeholder=""
          rows={10}
        />
      </>
    </CommonLayout>
  );
};

export default PageAddListing4;
