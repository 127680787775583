import { ReactNode } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import { useGetVenueByIdQuery } from "state/api/apiSlice";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const { id } = useParams<{ id: string }>();
  const { data: venueData, error, isLoading } = useGetVenueByIdQuery({ venue_id: Number(id) });
  const modal = searchParams?.get("modal");

  const handleCloseModalImageGallery = () => {

    let params = new URLSearchParams(document.location.search);
    params.delete("modal");

    // Construct the new URL path properly and remove trailing slash if present
    let newUrl = thisPathname.replace(/\/+$/, '');

    // Only add the query string if it's not empty
    if (params.toString()) {
      newUrl += `?${params.toString()}`;
    }

    navigate(newUrl);
  };

  const getImageGalleryListing = () => {
    if (venueData) {
      return venueData?.venue.images;
    }
    return

    return [];
  };

  return (
    <div className="ListingDetailPage py-6">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>



      {/* STICKY FOOTER MOBILE */}
      {venueData?.venue &&<MobileFooterSticky venue={venueData.venue}/>}
    </div>
  );
};

export default DetailPagetLayout;
