export const convertSelectedDateToString = (date: Date, time?: string) => {
  // Create a new date object to avoid mutating the original date
  const adjustedDate = new Date(date.getTime());

  // If a time is provided, parse and add it to the date
  if (time) {
    const [hours, minutes] = time.split(':').map(Number);
    adjustedDate.setHours(hours, minutes, 0, 0);
  }

  // Get the components of the date
  const year = adjustedDate.getFullYear();
  const month = String(adjustedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(adjustedDate.getDate()).padStart(2, '0');
  const hours = String(adjustedDate.getHours()).padStart(2, '0');
  const minutes = String(adjustedDate.getMinutes()).padStart(2, '0');
  const seconds = String(adjustedDate.getSeconds()).padStart(2, '0');

  // Calculate GMT offset
  const gmtOffset = adjustedDate.getTimezoneOffset();
  const sign = gmtOffset > 0 ? '-' : '+';
  const offsetHours = String(Math.abs(Math.floor(gmtOffset / 60))).padStart(2, '0');
  const offsetMinutes = String(Math.abs(gmtOffset % 60)).padStart(2, '0');
  const gmtString = `GMT${sign}${offsetHours}${offsetMinutes}`;

  // Construct the date string
  const dateString = `${year}-${month}-${day}`;
  const timeString = `${hours}:${minutes}:${seconds}`;

  // Return the date string with time and GMT offset if provided, otherwise just the date string
  return  `${dateString}T${timeString} ${gmtString}`;  
};


export const convertDateToDateOnlyString = (date: Date) =>{
  const adjustedDate = new Date(date.getTime());
  const year = adjustedDate.getFullYear();
  const month = String(adjustedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(adjustedDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}