import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetOrdersByVenueIdQuery } from "state/api/apiSlice";
import DefaultLoading from "shared/Loading/DefaultLoading";
import ProviderOrderCard from "./ProviderOrderCard";
import { useParams } from "react-router-dom";
import { Order, OrderStatus } from "types/order";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { orderStatusTranslations, translateOrderStatus } from "utils/translators";

export interface ProviderOrdersPageProps {
    className?: string;
}

const ProviderOrdersPage: FC<ProviderOrdersPageProps> = ({ className = "" }) => {
    const { id } = useParams<{ id: string }>();
    const { data, error, isLoading } = useGetOrdersByVenueIdQuery({ venue_id: Number(id) });
    const { currentLanguage } = useSelector((state: RootState) => state.language);

    const { t } = useTranslation();
    
    const [selectedStatus, setSelectedStatus] = useState<OrderStatus | null>(null);

    if (isLoading) {
        return <DefaultLoading />;
    }

    if (error) {
        return <div>{t("error.loadingOrders")}</div>;
    }

    const splitOrdersByStatus = (orders: Order[]) => {
        return orders.reduce<Record<OrderStatus, Order[]>>((acc, order) => {
            if (!acc[order.status]) {
                acc[order.status] = [];
            }
            acc[order.status].push(order);
            return acc;
        }, {
            'Pending Approval': [],
            'Approved - Pending Payment': [],
            'Payment Expired': [],
            'Declined': [],
            'Confirmed': [],
        });
    };

    const ordersByStatus = splitOrdersByStatus(data?.orders ?? []);

    return (
        <div className={`${className} space-y-6 p-6`}>
            <HelmetProvider>
                <Helmet>
                    <title>{t('navigation.orders')}</title>
                </Helmet>
            </HelmetProvider>
            <div>
                <h2 className="text-xl font-semibold mb-4">{t('order.reservations')}</h2>
                <div className="space-y-2 space-x-3 rtl:space-x-reverse">
                    {Object.keys(ordersByStatus).map((status) => (
                        <button
                            key={status}
                            onClick={() => setSelectedStatus(status as OrderStatus)}
                            className={`py-2 px-4 rounded transition duration-300 
                              ${selectedStatus === status ? 'bg-blue-500 text-white font-bold' : 'bg-gray-200 text-black hover:bg-blue-100'}`}
                        >
                    
                            {currentLanguage === 'en' ? status : translateOrderStatus(status as keyof typeof orderStatusTranslations)}

                        </button>
                    ))}
                </div>
            </div>
            {selectedStatus && (
                <div>
                    <h2 className="text-xl font-semibold mb-4"> 
                        {currentLanguage === 'en' ? selectedStatus : translateOrderStatus(selectedStatus as keyof typeof orderStatusTranslations)}
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {ordersByStatus[selectedStatus].map((order) => (
                            <ProviderOrderCard
                                key={order.id}
                                data={order}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProviderOrdersPage;
