import React, { FC, useEffect, useState } from "react";
import StayDatesRangeInput from "./StayDatesRangeInput";
import GuestsInput from "./GuestsInput";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StartRating from "components/StartRating/StartRating";
import { CONSTANTS } from "data/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { setNewOrder, setNewOrderCapacity, setNewOrderFromDate, setNewOrderToDate, setNewOrderVenueId, setOrderVenue } from "state/orderSlice";
import {convertSelectedDateToString} from "utils/convertSelectedDateToString";
import { Venue } from "types/venue";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateOrderMutation } from "state/api/apiSlice";
import DefaultLoading from "shared/Loading/DefaultLoading";
import { CreateOrderRequest } from "types/order";
import { calculateDays } from "utils/calculateDays";

const getDatesArray = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const datesArray = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
        datesArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesArray;
};

interface DailySideBarProps {
    venue: Venue;
}

const DailySideBar: FC<DailySideBarProps> = ({ venue }) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const newOrderParameters = useSelector((state: RootState) => state.order.newOrderParameters);
    const capacity = useSelector((state: RootState) => state.order.newOrderParameters.capacity);
    const [createOrder, { isLoading, error }] = useCreateOrderMutation();

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setNewOrderVenueId(venue.id));
        !capacity && dispatch(setNewOrderCapacity(1));
    }, []);


    const handleDateChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start !== null)
            dispatch(setNewOrderFromDate(convertSelectedDateToString(start, venue.earliest_check_in_hour)));
        if (end !== null)
            dispatch(setNewOrderToDate(convertSelectedDateToString(end, venue.latest_check_out_hour)));
    };

    const handleCapacityChange = (value: number) => {
        dispatch(setNewOrderCapacity(value));
    };

    const onReserveClick = async () => {
        dispatch(setNewOrderVenueId(venue.id));
        if (startDate && endDate && capacity && newOrderParameters.venue_id) {
            const response = await createOrder(newOrderParameters);

            if ('data' in response) {
                dispatch(setNewOrder(response.data!.order));
                dispatch(setOrderVenue(response.data!.venue));
                navigate(`/order-status/${response.data!.order.id}`);
            }
        }
    };

    const unavailableDates = venue.unavailable_dates.flatMap(range => getDatesArray(range[0], range[1]));


    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + venue.reservation_max_days_ahead);
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    // Calculate number of nights
    const days = startDate && endDate ? calculateDays(startDate, endDate) : 0;

    return (
        <div className="listingSectionSidebar__wrap shadow-xl">
            {/* PRICE */}
            <div className="flex justify-between">
                <span className="text-3xl font-semibold">
                    {venue.budget} {t('currency.sar')}
                    <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                        /{t('venueDetails.day')}
                    </span>
                </span>
                {!!venue.star_rating && <StartRating point={venue.star_rating} />}
            </div>

            {/* FORM */}
            <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl">
                <StayDatesRangeInput
                    className="flex-1 z-[11]"
                    startDate={startDate}
                    endDate={endDate}
                    onDateChange={handleDateChange}
                    unavailableDates={unavailableDates}
                    minDate={minDate}
                    maxDate={maxDate}
                />
                <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                <GuestsInput
                    capacity={capacity ?? 1}
                    maxCapacity={venue.capacity}
                    setCapacity={handleCapacityChange}
                    className="flex-1"
                />
            </form>

            {/* SUM */}
            {
                startDate && endDate && <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>{t('currency.sar')} {venue.budget} x {days} {t('venueDetails.days')}</span>
                    </div>

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <span>Total</span>
                        {/* Replace 199 with actual total calculation */}
                        <span>{t('currency.sar')} {(days * venue.budget).toFixed(2)}</span>
                    </div>
                </div>
            }

            {/* SUBMIT */}
            <ButtonPrimary disabled={!startDate || !endDate} onClick={onReserveClick}>
                {isLoading ? <DefaultLoading /> : t('order.reserve')}
            </ButtonPrimary>
            {!startDate || !endDate && <p className="text-primary-800 text-sm">{t('order.makeSureCheckInDateAndACheckoutDatesAreSelected')}</p>}
            {error && <p className="text-red-500 text-sm">{t('order.pleaseLoginToReserve')}</p>}
        </div>
    );
};

export default DailySideBar;
