import React, { Fragment, FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";

import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useTranslation } from "react-i18next";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";

export interface CustomDatePickerInputProps {
  className?: string;
  fieldClassName?: string;
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
}

const CustomDatePickerInput: FC<CustomDatePickerInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding ]",
  selectedDate,
  onDateChange,
  minDate,
  maxDate,
}) => {

  const { t } = useTranslation();

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-start">
          <span className="block text-sm xl:text-lg font-semibold">
            {selectedDate ? selectedDate.toLocaleDateString() : "Add date"}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {t('venueDetails.checkIn')}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`CustomDatePickerInput z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 rtl:space-x-reverse focus:outline-none ${open ? "nc-hero-field-focused" : ""
              }`}
            onClickCapture={() => document.querySelector("html")?.click()}
          >
            {renderInput()}
            {selectedDate && open && (
              <ClearDataButton onClick={() => onDateChange(null)} />
            )}
          </Popover.Button>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={selectedDate}
                  onChange={onDateChange}
                  minDate={minDate}
                  maxDate={maxDate}
                  dateFormat="MMMM d, yyyy"
                  monthsShown={2}
                  showPopperArrow={false}
                  inline
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay dayOfMonth={day} date={date} />
                  )}
                  className="p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default CustomDatePickerInput;
