import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { DateRange } from 'types/venue';
import CustomDatePickerInput from './CustomDatePickerInput';

interface HourlyDatePickerProps {
    selectedDate: Date | null;
    onDateChange: (date: Date | null) => void;
    selectedTime: Date | null;
    onSelectedTimeChange: (time: Date | null) => void;
    earliest_check_in_hour: string;
    latest_check_out_hour: string;

    minDate?: Date;
    maxDate?: Date;
    unavailableDates: [string, string][];
}

const HourlyDatePicker: React.FC<HourlyDatePickerProps> = ({
    earliest_check_in_hour,
    latest_check_out_hour,
    selectedDate,
    onDateChange,
    selectedTime,
    onSelectedTimeChange,
    minDate,
    maxDate,
    unavailableDates
}) => {
    const [disableTimes, setDisableTimes] = useState<Date[]>([]);

    useEffect(() => {
        function extractUnavailableHours(unavailableDates: DateRange[], targetDate: Date) {
            const targetDayStart = new Date(targetDate);
            targetDayStart.setHours(0, 0, 0, 0);
            const targetDayEnd = new Date(targetDate);
            targetDayEnd.setHours(23, 59, 59, 999);

            const unavailableHours = new Set<number>();

            unavailableDates.forEach(([start, end]) => {
                const startDate = new Date(start);
                const endDate = new Date(end);

                if (startDate <= targetDayEnd && endDate >= targetDayStart) {
                    const rangeStart = startDate < targetDayStart ? targetDayStart : startDate;
                    const rangeEnd = endDate > targetDayEnd ? targetDayEnd : endDate;

                    for (let hour = rangeStart.getHours(); hour <= rangeEnd.getHours(); hour++) {
                        unavailableHours.add(hour);
                    }
                }
            });

            return Array.from(unavailableHours).sort((a: number, b: number) => a - b);
        }

        const disableHours = extractUnavailableHours(unavailableDates, selectedDate ?? new Date());
        const newDisableTimes = disableHours.map(hour => {
            const date = new Date();
            date.setHours(hour, 0, 0, 0);
            return date;
        });
        console.log(disableTimes)


        setDisableTimes(newDisableTimes);
    }, [selectedDate, unavailableDates]);

    const parseTimeString = (timeString: string) => {
        const [hours, minutes] = timeString.split(":").map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    };

    return (
        <div className="p-6 bg-white rounded-lg shadow-lg">
            <div className="mb-4">
                <CustomDatePickerInput
                selectedDate={selectedDate}
                onDateChange={onDateChange}
                className="p-2 border border-gray-300 rounded-md w-full"
                minDate={minDate}
                maxDate={maxDate}/>
            </div>
            {selectedDate && (
                <>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Reservation Start Time (1 hour)</label>
                        <DatePicker
                            selected={selectedTime}
                            onChange={onSelectedTimeChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            minTime={parseTimeString(earliest_check_in_hour)}
                            maxTime={parseTimeString(latest_check_out_hour)}
                            timeCaption="Reservation time"
                            dateFormat="h:mm aa"
                            className="p-2 border border-gray-300 rounded-md w-full"
                            excludeTimes={disableTimes}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default HourlyDatePicker;
