import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import CommonLayout from "./CommonLayout";
import ImagePicker from "./ImagePicker";
import { useUploadVenueImagesMutation } from "state/api/apiSlice";
import { ImageListType } from "react-images-uploading";
import { setListedVenue } from "state/addVenueSlice";

export interface PageAddListing9Props { }

const PageAddListing9: FC<PageAddListing9Props> = () => {
  const venue = useSelector((state: RootState) => state.addVenue.listedVenue);
  const [uploadVenueImages, { isLoading, isError, isSuccess }] = useUploadVenueImagesMutation();
  const [selectedImages, setSelectedImages] = useState<ImageListType>([]);
  const [imagesSubmitted, setImagesSubmitted] = useState(false); // Track if images are submitted
  const dispatch = useDispatch();
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const minNumOfPics = 5;
  const maxNumOfPics = 10;

  const handleImageSubmit = (images: ImageListType) => {
    setSelectedImages(images);
    setImagesSubmitted(true); // Set to true when images are submitted
  };

  const runBeforeNext = async () => {
    if (!venue || !venue.id) {
      console.error("No venue found or venue id is missing");
      return;
    }

    try {
      const listedVenue = await uploadVenueImages({ venueId: venue.id, images: selectedImages }).unwrap();
      dispatch(setListedVenue(listedVenue.venue));
    } catch (error) {
      console.error("Error uploading images:", error);
      throw error;
    }
  };

  return (
    <CommonLayout
      index="09"
      backtHref="/add-listing-8"
      nextHref="/add-listing-10"
      runBeforeNext={runBeforeNext}
      isNextButtonDisabled={!imagesSubmitted} // Disable the continue button if no images are submitted
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Upload {minNumOfPics}+ Pictures of your venue</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Upload between {minNumOfPics} to {maxNumOfPics} pictures and make sure each file does not exceed 5mbs
          </span>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <ImagePicker minNumber={minNumOfPics} maxNumber={maxNumOfPics} onSubmit={handleImageSubmit} />
      </>
    </CommonLayout>
  );
};

export default PageAddListing9;
