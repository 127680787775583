"use client";

import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { setSearchType } from "state/venueSearchSlice";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import { VenueTypes } from "./type";
import { useTranslation } from "react-i18next";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: VenueTypes;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "wedding_hall",
}) => {
  const tabs: VenueTypes[] = ["wedding_hall", "conference_hall", "restaurant"];
  const [tabActive, setTabActive] = useState<VenueTypes>(currentTab);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const renderTab = () => {
    return (
      <ul className="ms-2 sm:ms-6 md:ms-12 flex space-x-5 rtl:space-x-reverse sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => {
                setTabActive(tab);
                dispatch(setSearchType(tab));
              }}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${active
                ? ""
                : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
                }`}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 me-2" />
              )}
              <span>{t(`venueTypes.${tab}`)}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}>
      {renderTab()}
      <div className="hidden sm:block">
        <StaySearchForm />
      </div>
    </div>
  );
};

export default HeroSearchForm;
