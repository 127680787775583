import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import MyRouter from "routers/index";
import { RootState, AppDispatch } from "state/store"; // Ensure AppDispatch is imported
import { fetchUser } from "state/userSlice";

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>(); // Use AppDispatch type for dispatch
  const { details } = useSelector((state: RootState) => state.user);
  console.log('user ', details);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  document.body.dir = i18n.dir();
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
