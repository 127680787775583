import React from 'react';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { User } from 'types/auth';
import { CONSTANTS } from 'data/constants';

interface AccountSideBarProps {
    user: User;
}

const AccountSideBar: React.FC<AccountSideBarProps> = ({ user }) => {
    const { t } = useTranslation();
    return (
        <div className="w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
            {user.profile_picture && <Avatar sizeClass="w-28 h-28" imgUrl={user.profile_picture} />}
            {/* ---- */}
            <div className="space-y-3 text-center flex flex-col items-center">
                <h2 className="text-3xl font-semibold">{user.type === CONSTANTS.userType.customer ? `${user.first_name} ${user.last_name}` : `${user.company_name}`}</h2>
            </div>
            <Badge name={t(`auth.${user.type}`)} />
            {user.type === CONSTANTS.userType.provider && <Badge name={user.approval_status} />}
            
            {/* ---- */}
            <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

            {/* ---- */}
            <div className="space-y-4">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                    <PhoneIcon className="h-6 w-6 text-neutral-400" />
                    <span className="text-neutral-600 dark:text-neutral-300">{user.phone_number}</span>
                </div>
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                    <EnvelopeIcon className="h-6 w-6 text-neutral-400" />
                    <span className="text-neutral-600 dark:text-neutral-300">{user.email}</span>
                </div>
            </div>
            {/* ---- */}
            {user.type === CONSTANTS.userType.provider && (
                <div className="bg-primary-500 text-white text-lg font-semibold p-4 rounded-lg shadow-md">
                    <div className="flex items-center justify-between">
                        <span>{t('user.walletAmount')}: {' '}</span>
                        <span>{user.wallet_amount} {t('currency.sar')}</span>
                    </div>
                </div>
            )}
            
        </div>
    );
};

export default AccountSideBar;
