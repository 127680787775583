
import React, { FC, Fragment, useState } from "react";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import MoyasarPayment from "containers/ListingDetailPage/listing-stay-detail/MoyasarPayment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetOrderByIdQuery, useGetVenueByIdQuery } from "state/api/apiSlice";
import StatusSection from "containers/OrderStatusPage.tsx/StatusSection";
import { calculateDays } from "utils/calculateDays";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const { id } = useParams<{ id: string }>();
  const { data: orderData, error: orderError, isLoading: orderLoading } = useGetOrderByIdQuery({ order_id: Number(id) });
  const { data: venueData, error: venueError, isLoading: venueLoading } = useGetVenueByIdQuery({ venue_id: Number(orderData?.order.venue_id) });
  const { t } = useTranslation();
  const days = orderData ? calculateDays(orderData.order.reservation_period.lower, orderData.order.reservation_period.upper) : 0;

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              {venueData?.venue.images[0] && <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={venueData?.venue.images[0].image_url}
              />}
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {venueData?.venue.name}
              </span>
              <span className="text-base font-medium mt-1 block">
                {venueData?.venue.city}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {venueData?.venue.provider.company_name}
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            {!!venueData?.venue.star_rating && <StartRating point={venueData?.venue.star_rating} />}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{days} {t('venueDetails.days')}</span>
            <span>{t('currency.sar')} {venueData?.venue.budget}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{t('currency.sar')} {orderData?.order.price}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your reservation</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
      
            <div
              className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
            >
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-md font-semibold">
                  From : {orderData?.order.reservation_period.lower}
                </span>
                <span className="mt-1.5 text-md font-semibold">
                  To : {orderData?.order.reservation_period.upper}
                </span>
              </div>
            </div>

            <div
              className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
            >
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">
                  <span className="line-clamp-1">
                    {`${orderData?.order.capacity} Guests`}
                  </span>
                </span>
              </div>
            </div>
              
          </div>
        </div>

        <div>
          {orderData && orderData.order.status === 'Approved - Pending Payment' && <MoyasarPayment order={orderData.order} />}
          {orderData && orderData.order.status !== 'Approved - Pending Payment' && <StatusSection status={orderData.order.status} orderId={orderData.order.id} />}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
