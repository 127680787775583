import React from "react";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "state/store";

export interface Heading2Props {
  className?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
}) => {
  const { location, capacity, type, date } = useSelector((state: RootState) => state.venueSearch)
  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      <h2 className="text-4xl font-semibold">{`Venues in ${location}`}</h2>
      <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
        {date}
        <span className="mx-2">·</span>{`${capacity} guests`}
      </span>

    </div>
  );
};

export default Heading2;
